import { create } from 'zustand';
import { Product } from '@models/product';

interface ProductPreViewModal {
  show?: boolean;
  data?: Product;
  open: (data: Product) => void;
  close: () => void;
}

const useProductPreViewModalStore = create<ProductPreViewModal>((set) => ({
  show: false,
  data: undefined,
  close: () => set({ show: false, data: undefined }),
  open: (data) => set({ data, show: true }),
}));

export default useProductPreViewModalStore;
