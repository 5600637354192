import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

export const useViewport = () => {
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  const resize = () => {
    setInnerWidth(window.innerWidth);
  };
  return { isMobile: innerWidth < 800 || isMobile, screenWidth: innerWidth };
};
