import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useComponentSize } from 'react-use-size';
import VisibilitySensor from '@zelty/react-visibility-sensor';
import { OverlayScrollbars } from 'overlayscrollbars';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import AboutCaptionImage from '@assets/images/productdetailview/caption-about-copy@3x.png';
import HowToCaptionImage from '@assets/images/productdetailview/caption-howto@3x.png';
import NeedsCaptionImage from '@assets/images/productdetailview/caption-needs@3x.png';
import RecommendCaptionImage from '@assets/images/productdetailview/caption-recommend@3x.png';
import { ReactComponent as ChatPaymentBadge } from '@assets/images/productdetailview/chat-and-payment.svg';
import { ReactComponent as DirectPayemntBadge } from '@assets/images/productdetailview/direct-payment.svg';
import { ReactComponent as FavoritedIcon } from '@assets/images/productdetailview/favorited-icon.svg';
import ArrowRightIcon from '@assets/images/productdetailview/icon-arr-dark-right.png';
import ChildIcon from '@assets/images/productdetailview/icon-detail-child.png';
import ClockIcon from '@assets/images/productdetailview/icon-detail-clock.png';
import DeliveryIcon from '@assets/images/productdetailview/icon-detail-delivery.png';
import LocationIcon from '@assets/images/productdetailview/icon-detail-location.png';
import MegaphoneIcon from '@assets/images/productdetailview/icon-detail-megaphone.png';
import ExclamationIcon from '@assets/images/productdetailview/icon-exclamation-red.png';
import HighFiveIcon from '@assets/images/productdetailview/icon-high-five.png';
import QuotesIcon from '@assets/images/productdetailview/icon-quotes@3x.png';
import { ReactComponent as ShareIcon } from '@assets/images/productdetailview/share-icon.svg';
import TutorDefaultAvatar from '@assets/images/productdetailview/tutorintro.png';
import { Carousel } from '@components/common/Carousel';
import { ChevronDownIcon, ChevronUpIcon } from '@components/common/Icons';
import { CLASS_REFUND_POLICY, COMMERCE_REFUND_POLICY } from '@consts/product';
import { PRODUCT_ACTIVITY_TYPE_TEXT, Product, ProductEditModel } from '@models/product';
import { getAddressesText } from '@utils/dataTransform';
import { cdnImageUrl } from '@utils/image';
import AppServiceCautionsModal from './AppServiceCautionsModal';
import './ProductDetailView.scss';

const HEADER_HEIGHT = '56px';
const Divider: React.FC<{ style?: any }> = (props) => {
  return (
    <div style={{ width: '100%', padding: '0 16px', ...props.style }}>
      <div style={{ height: '1px', width: '100%', backgroundColor: '#EFF1F4' }}></div>
    </div>
  );
};

const DividerBold: React.FC<{ style?: any }> = (props) => {
  return <div style={{ height: '8px', width: '100%', backgroundColor: '#EFF1F4', ...props.style }}></div>;
};

const DetailBodyImages: React.FC<{ images: string[] }> = (props) => {
  const [showExpandButton, setShowExpandButton] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const productDetail = useComponentSize();

  useEffect(() => {
    if (productDetail.height > 1150) {
      setShowExpandButton(true);
    } else if (productDetail.height === 1150 && !showMore) {
      setShowExpandButton(true);
    } else {
      setShowExpandButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetail.height]);

  return (
    <div>
      <div
        ref={productDetail.ref}
        className={'product-detail-info'}
        style={{ height: !showExpandButton ? 'auto' : showMore ? 'auto' : '1150px', overflow: 'hidden' }}>
        {(props.images || []).map((bodyImage, idx) => {
          return (
            <img
              key={`body-image-${idx}`}
              src={cdnImageUrl(bodyImage)}
              alt=""
              style={{ width: '100%', display: 'block' }}
            />
          );
        })}
      </div>
      {showExpandButton && (
        <div style={{ padding: '16px', position: 'relative', width: '100%' }}>
          {!showMore && (
            <div
              style={{
                height: '140px',
                width: '100%',
                background: 'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))',
                position: 'absolute',
                top: -140,
                left: 0,
                right: 0,
                zIndex: 99999,
              }}></div>
          )}
          <div
            style={{
              display: 'flex',
              border: '1px solid #FF509A',
              borderRadius: '8px',
              height: '56px',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              marginTop: 0,
              cursor: 'pointer',
            }}
            onClick={() => {
              setShowMore((value) => !value);
            }}>
            <div
              style={{
                color: 'var(--Primary-95, #FF3D8F)',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '22px',
              }}>
              {showMore ? '상세정보 접기' : '상세정보 펼치기'}
            </div>
            <div style={{ marginLeft: '8px' }}>
              {showMore ? (
                <ChevronUpIcon color={'#FF509A'} style={{ width: '20px', height: '20px' }} />
              ) : (
                <ChevronDownIcon color={'#FF509A'} style={{ width: '20px', height: '20px' }} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// const CommerceDetailInformation: React.FC<{ detailHtml: string }> = (props) => {
//   const [showExpandButton, setShowExpandButton] = useState<boolean>(false);
//   const [showMore, setShowMore] = useState<boolean>(false);
//   const productDetail = useComponentSize();

//   useEffect(() => {
//     if (productDetail.height > 1150) {
//       setShowExpandButton(true);
//     } else if (productDetail.height === 1150 && !showMore) {
//       setShowExpandButton(true);
//     } else {
//       setShowExpandButton(false);
//     }
//   }, [productDetail.height]);

//   return (
//     <div>
//       <div
//         ref={productDetail.ref}
//         className={'product-detail-info'}
//         style={{ height: !showExpandButton ? 'auto' : showMore ? 'auto' : '1150px', overflow: 'hidden' }}>
//         <Linkify>{props.detailHtml}</Linkify>
//       </div>
//       {showExpandButton && (
//         <div style={{ padding: '16px', position: 'relative', width: '100%' }}>
//           {!showMore && (
//             <div
//               style={{
//                 height: '140px',
//                 width: '100%',
//                 background: 'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))',
//                 position: 'absolute',
//                 top: -140,
//                 left: 0,
//                 right: 0,
//                 zIndex: 99999,
//               }}></div>
//           )}
//           <div
//             style={{
//               display: 'flex',
//               border: '1px solid #FF509A',
//               borderRadius: '8px',
//               height: '56px',
//               alignItems: 'center',
//               justifyContent: 'center',
//               flexDirection: 'row',
//               marginTop: 0,
//               cursor: 'pointer',
//             }}
//             onClick={() => {
//               setShowMore((value) => !value);
//             }}>
//             <div
//               style={{
//                 color: 'var(--Primary-95, #FF3D8F)',
//                 textAlign: 'center',
//                 fontFamily: '"Pretendard Variable"',
//                 fontSize: '16px',
//                 fontStyle: 'normal',
//                 fontWeight: 600,
//                 lineHeight: '22px',
//               }}>
//               {showMore ? '상세정보 접기' : '상세정보 펼치기'}
//             </div>
//             <div style={{ marginLeft: '8px' }}>
//               {showMore ? (
//                 <ChevronUpIcon color={'#FF509A'} style={{ width: '20px', height: '20px' }} />
//               ) : (
//                 <ChevronDownIcon color={'#FF509A'} style={{ width: '20px', height: '20px' }} />
//               )}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

const ShippingInfo: React.FC<{ data: Product }> = (props) => {
  const data = props.data;
  return (
    <div style={{ padding: '20px 16px' }}>
      <div className="product-detail-title">배송정책</div>
      <div style={{ color: '#66686b', fontSize: '13px', lineHeight: '20px', whiteSpace: 'pre-wrap' }}>
        {data?.shippingPolicyText}
      </div>
    </div>
  );
};

const RefundPolicy: React.FC<{ data: Product }> = (props) => {
  const data = props.data;

  if (!data?.refundPolicies || data?.refundPolicies.length === 0) {
    return <div></div>;
  }

  return (
    <div style={{ padding: '20px 16px' }}>
      <div className="product-detail-title">교환/환불정책</div>
      <div style={{ color: '#66686b', fontSize: '13px', lineHeight: '20px', whiteSpace: 'pre-wrap' }}>
        {data?.refundPolicies[0]}
      </div>
      <div className="product-detail-subtitle">교환 안내</div>
      <div style={{ color: '#66686b', fontSize: '13px', lineHeight: '20px', whiteSpace: 'pre-wrap' }}>
        {data?.refundPolicies[1]}
      </div>
      <div className="product-detail-subtitle">환불 안내</div>
      <div style={{ color: '#66686b', fontSize: '13px', lineHeight: '20px', whiteSpace: 'pre-wrap' }}>
        {data?.refundPolicies[2]}
      </div>
      <div className="product-detail-subtitle">교환 및 환불 신청 방법</div>
      <div style={{ color: '#66686b', fontSize: '13px', lineHeight: '20px', whiteSpace: 'pre-wrap' }}>
        {data?.refundPolicies[3]}
      </div>
    </div>
  );
};

export const ClassRefundPolicy: React.FC<{ data: Product }> = (props) => {
  const data = props.data;
  const refundPolicies: string[] = (
    data?.refundPolicyText || data.saleType === 'class' ? CLASS_REFUND_POLICY : COMMERCE_REFUND_POLICY
  )
    .split('\n')
    .map((line: string) => {
      return line.trim().replace(/^-/, '');
    })
    .filter((line: string) => line.length > 0);

  return (
    <div
      style={{
        marginTop: '32px',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingBottom: 0,
      }}>
      <div
        style={{
          fontSize: '20px',
          fontWeight: 'bold',
          lineHeight: '26px',
          letterSpacing: -0.6,
        }}>
        클래스 환불정책
      </div>
      <div style={{ marginTop: '6px' }}>
        <div
          style={{
            fontSize: '14px',
            lineHeight: '21px',
            fontWeight: 'normal',
            color: '#66686b',
            marginTop: '4px',
            width: '100%',
            letterSpacing: -0.4,
            flexWrap: 'wrap',
          }}>
          튜터님마다 환불정책이 다를 수 있습니다. 채팅상담 시 환불정책을 꼭 확인해주세요.
        </div>
      </div>
      {data?.classTermsText ? (
        <div style={{ whiteSpace: 'pre-wrap' }}>{data?.classTermsText}</div>
      ) : (
        refundPolicies.map((item: string, index) => {
          return (
            <div
              key={`terms-${index}`}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginTop: '8px',
                marginLeft: '4px',
              }}>
              <div
                style={{
                  backgroundColor: '#66686b',
                  borderRadius: '50%',
                  width: '4px',
                  height: '4px',
                  marginRight: '10px',
                  marginTop: '8px',
                }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#424242',
                  lineHeight: '22px',
                  width: '100%',
                  letterSpacing: -0.4,
                  flexWrap: 'wrap',
                }}>
                {item}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

const ProductRefundPolicy: React.FC<{ data: Product }> = (props) => {
  const data = props.data;

  if (!data?.refundPolicyText && data?.refundPolicies && data?.refundPolicies.length > 0) {
    return <RefundPolicy data={props.data} />;
  }

  if (!data?.refundPolicyText && data?.classTermsText) {
    return <ClassRefundPolicy data={props.data} />;
  }

  const refundPolicies: string[] = (
    data?.refundPolicyText || (data.saleType === 'class' ? CLASS_REFUND_POLICY : COMMERCE_REFUND_POLICY)
  )
    .split('\n')
    .map((line: string) => {
      return line.trim();
    })
    .filter((line: string) => line.length > 0);

  return (
    <div
      style={{
        marginTop: '32px',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingBottom: 0,
      }}>
      <div
        style={{
          fontSize: '20px',
          fontWeight: 'bold',
          lineHeight: '26px',
          letterSpacing: -0.6,
        }}>
        환불정책
      </div>
      <div style={{ marginTop: '8px' }}>
        {refundPolicies.map((item: string, index) => {
          return (
            <div
              key={`terms-${index}`}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginTop: '8px',
                marginLeft: '4px',
              }}>
              <div
                style={{
                  fontSize: '14px',
                  color: '#424242',
                  lineHeight: '22px',
                  width: '100%',
                  letterSpacing: -0.4,
                  flexWrap: 'wrap',
                }}>
                {item}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ProductInformation: React.FC<{ data: Product }> = (props) => {
  const data = props.data;

  const activityType = data?.activityType || 'experiential';
  const activityTypeIcon = PRODUCT_ACTIVITY_TYPE_TEXT[activityType]?.icon;

  return (
    <div style={{ padding: '28px 16px' }}>
      <div className="product-detail-title">상품 정보</div>
      <div style={{ padding: '20px 0' }}>
        {activityType === 'delivery' && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <img style={{ width: '24px', height: '24px', marginRight: '16px' }} src={DeliveryIcon} alt="" />
              <div
                style={{
                  fontSize: '18px',
                  lineHeight: '24px',
                  fontWeight: 500,
                  color: '#242424',
                }}>
                택배비
                {data?.shippingFee === 0 ? ` 무료` : ` ${(data?.shippingFee || 0).toLocaleString()}원`}
              </div>
            </div>
            <div style={{ marginLeft: '40px' }}>
              <div
                style={{
                  color: '#808387',
                  fontSize: '14px',
                  lineHeight: '21px',
                  marginTop: '4px',
                  whiteSpace: 'pre-wrap',
                }}>
                {data?.sellerShortComment}
              </div>
            </div>
          </>
        )}
        {data?.classDuration && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <img style={{ width: '24px', height: '24px', marginRight: '16px' }} src={ClockIcon} alt="" />
            <div
              style={{
                fontSize: '18px',
                lineHeight: '24px',
                fontWeight: 500,
                color: '#242424',
              }}>
              {data?.classDuration}분
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '16px',
          }}>
          <img style={{ width: '24px', height: '24px', marginRight: '16px' }} src={activityTypeIcon} alt="" />
          <div
            style={{
              flex: '1px',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}>
            <div
              style={{
                fontSize: '18px',
                lineHeight: '24px',
                fontWeight: 500,
                color: '#242424',
                flex: 1,
              }}>
              {activityType && <div>{PRODUCT_ACTIVITY_TYPE_TEXT[activityType]?.title}</div>}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: '1px',
          }}>
          <div style={{ marginLeft: '40px' }}>
            {PRODUCT_ACTIVITY_TYPE_TEXT[activityType]?.description && (
              <div
                style={{
                  color: '#808387',
                  fontSize: '14px',
                  lineHeight: '21px',
                  marginTop: '4px',
                }}>
                {PRODUCT_ACTIVITY_TYPE_TEXT[activityType]?.description}
              </div>
            )}
            {activityType !== 'delivery' && data?.sellerShortComment && (
              <div
                style={{
                  color: '#808387',
                  fontSize: '14px',
                  lineHeight: '21px',
                  marginTop: '4px',
                  whiteSpace: 'pre-wrap',
                }}>
                {data?.sellerShortComment}
              </div>
            )}
          </div>
        </div>

        {['studio', 'experiential', 'home'].includes(activityType) && data?.location && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '16px',
              marginBottom: '4px',
              width: '100%',
              alignItems: 'flex-start',
            }}>
            <img style={{ width: '24px', height: '24px', marginRight: '16px' }} src={LocationIcon} alt="" />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                flex: '1px',
              }}>
              <div>
                <div
                  style={{
                    fontSize: '18px',
                    lineHeight: '24px',
                    fontWeight: 500,
                    color: '#242424',
                    whiteSpace: 'pre-wrap',
                  }}>
                  {getAddressesText({ availableArea: data?.location })}
                </div>
              </div>
            </div>
          </div>
        )}
        {data?.ageMin !== undefined && data?.ageMax !== undefined && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '16px',
            }}>
            <img style={{ width: '24px', height: '24px', marginRight: '16px' }} src={ChildIcon} alt="" />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <div
                  style={{
                    fontSize: '18px',
                    lineHeight: '24px',
                    fontWeight: 500,
                    color: '#242424',
                  }}>
                  {`${data?.ageMin}세 ~ ${data?.ageMax > 13 ? '14세+' : `${data?.ageMax}세`}`}
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '16px',
          }}>
          <img style={{ width: '24px', height: '24px', marginRight: '16px' }} src={MegaphoneIcon} alt="" />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <div
                style={{
                  fontSize: '18px',
                  lineHeight: '24px',
                  fontWeight: 500,
                  color: '#242424',
                }}>
                구매 안내
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: '40px' }}>
          <div
            style={{
              color: '#808387',
              fontSize: '14px',
              lineHeight: '21px',
              marginTop: '4px',
              whiteSpace: 'pre-wrap',
            }}>
            {data?.sellerComment}
          </div>
        </div>
      </div>
      {data?.tags && data?.tags.length > 0 && (
        <>
          <Divider style={{ padding: 0 }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginTop: '20px',
            }}>
            {data?.tags.slice(0, 2).map((item, index) => {
              return (
                <div
                  key={`tag-${index}`}
                  style={{
                    display: 'flex',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    borderRadius: '6px',
                    backgroundColor: '#eff1f4',
                    height: '28px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '8px',
                  }}
                  onClick={() => {
                    // this.onSearchDone(item);
                  }}>
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#808387',
                      fontWeight: 'bold',
                    }}>
                    {item}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

const DetailImages: React.FC<{ imageUrls?: string[]; soldout?: boolean }> = (props) => {
  const [currentPage, setCurrenPage] = useState<number>(1);
  const { imageUrls } = props;

  if (!imageUrls) {
    return <div></div>;
  }

  return (
    <div>
      <div
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          aspectRatio: 1,
          position: 'relative',
        }}>
        <Carousel
          options={{
            align: 'start',
            containScroll: 'trimSnaps',
          }}
          onChangeSlide={(currentSlide: number) => {
            setCurrenPage(currentSlide + 1);
          }}>
          {imageUrls.map((imageUrl: string, index: number) => {
            return (
              <img
                key={`product-image-${index}`}
                src={cdnImageUrl(imageUrl)}
                style={{ flex: '0 0 auto', width: '100%', objectFit: 'cover', aspectRatio: 1 }}
                alt=""></img>
            );
          })}
        </Carousel>
        {imageUrls.length > 1 && (
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              bottom: '12px',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: 'rgba(0,0,0,.6)',
              height: '24px',
              borderRadius: '12px',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0 10px',
            }}>
            <div
              style={{
                alignSelf: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'var(--White-100, #FFF)',
                fontFamily: 'Pretendard',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '100%',
              }}>
              <span style={{ alignSelf: 'center' }}>{currentPage}</span>
              <span style={{ alignSelf: 'center', width: '8px', textAlign: 'center' }}>/</span>
              <span style={{ color: 'rgba(255, 255, 255, 0.6)', alignSelf: 'center' }}>{imageUrls.length}</span>
            </div>
          </div>
        )}
      </div>
      {props.soldout && (
        <div
          style={{
            bottom: 0,
            height: '32px',
            width: '100%',
            backgroundColor: '#66686b',
            paddingLeft: '16px',
            paddingRight: '16px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'row',
          }}>
          <div
            style={{
              fontSize: '14px',
              lineHeight: '21px',
              alignSelf: 'center',
              fontWeight: 'bold',
              color: 'white',
            }}>
            SOLD OUT
          </div>
          <div
            style={{
              fontSize: '14px',
              lineHeight: '21px',
              alignSelf: 'center',
              color: 'white',
              marginLeft: '4px',
            }}>
            다음 오픈을 기다려 주세요!
          </div>
        </div>
      )}
    </div>
  );
};

const ProductSummary: React.FC<{ data: Product }> = (props) => {
  const [showGroupDiscountInfo, setShowGroupDiscountInfo] = useState<boolean>(false);
  const data = props.data;

  if (data?.applyPeriodDiscount && data?.discountInfo?.discountedFee) {
    const today = new Date();
    if (
      data?.discountInfo?.discountStartDate &&
      data?.discountInfo?.discountEndDate &&
      new Date(data?.discountInfo?.discountStartDate) < today &&
      new Date(data?.discountInfo?.discountEndDate) > today
    ) {
      data.fee = data?.discountInfo?.discountedFee || 0;
      data.discount = `${Math.floor(100 - (data.fee / data?.originFee) * 100)}%`;
    }
  }

  return (
    <div style={{ padding: '24px 16px' }}>
      {data.saleType === 'class' && (
        <div
          style={{
            display: 'flex',
            gap: '6px',
            color: 'var(--Primary-95, #FF3D8F)',
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 400,
            marginBottom: '12px',
          }}>
          <ChatPaymentBadge />
          <div style={{ width: '1px', height: '10px', background: '#FFC5DD' }}></div>
          <div>희망 옵션 상담 후 구매 가능</div>
        </div>
      )}
      {data.saleType === 'commerce' && (
        <div
          style={{
            display: 'flex',
            gap: '6px',
            color: '#7950EF',
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 400,
            marginBottom: '12px',
          }}>
          <DirectPayemntBadge />
          <div style={{ width: '1px', height: '10px', background: '#D5C5FF' }}></div>
          <div>희망 옵션 바로 구매 가능</div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <button
          className={'link-button'}
          style={{ cursor: data?.saleType === 'class' ? 'pointer' : 'auto' }}
          onClick={() => {}}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span
              style={{
                color: '#424242',
                fontSize: '14px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                height: '20px',
              }}>
              {data?.sellerName}
            </span>
            {/* <span
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '20px',
                marginLeft: '8px',
                color: 'var(--Neutral-60, #9B9DA0)',
                textAlign: 'right',
                fontFamily: 'Pretendard',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
              }}>{`조회수 ${compactNumber(1234567)}`}</span> */}
          </div>
        </button>
      </div>
      <div
        style={{
          marginTop: '12px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flex: '1px',
        }}>
        <div
          style={{
            fontSize: '18px',
            lineHeight: '24px',
            letterSpacing: -0.2,
            color: '#424242',
            flex: '1px',
            flexWrap: 'wrap',
          }}>
          {data?.title}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '14px',
        }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {data?.discount && (
            <div
              style={{
                color: 'var(--Primary-95, #FF3D8F)',
                fontFamily: 'Pretendard',
                fontSize: '22px',
                fontStyle: 'normal',
                fontWeight: 700,
                marginRight: '4px',
              }}>
              {data?.discount}
            </div>
          )}
          <div
            style={{
              fontFamily: 'Pretendard',
              fontSize: '22px',
              fontWeight: 'bold',
              color: '#242424',
            }}>
            {(data?.fee || 0).toLocaleString()}
          </div>
          <div
            style={{
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontWeight: 'normal',
              color: '#242424',
              lineHeight: '26px',
            }}>
            원
          </div>
          {data?.originFee && data.originFee > 0 ? (
            <div
              style={{
                fontFamily: 'Pretendard',
                marginLeft: '4px',
                textDecorationLine: 'line-through',
                color: '#b9bbbe',
                fontSize: '14px',
              }}>
              {`${data.originFee.toLocaleString()}원`}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {data?.applyGroupDiscount && (
        <div
          style={{
            marginTop: '20px',
            padding: '12px 16px',
            borderRadius: '8px',
            border: '1px solid #e8eaed',
            flexDirection: 'column',
            zIndex: 10,
          }}
          onClick={() => {
            setShowGroupDiscountInfo((value) => !value);
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <div style={{ display: 'flex' }}>
              <img src={HighFiveIcon} style={{ width: '20px', height: '20px', marginRight: '8px' }} alt="" />
              <div
                style={{
                  color: 'var(--Neutral-90, #424242)',
                  fontFamily: '"Pretendard Variable"',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                }}>
                그룹할인 혜택 최대 40% 할인
              </div>
            </div>
            {showGroupDiscountInfo ? (
              <ChevronUpIcon style={{ width: '16px', height: '16px' }} />
            ) : (
              <ChevronDownIcon style={{ width: '16px', height: '16px' }} />
            )}
          </div>
          {showGroupDiscountInfo && (
            <div style={{ marginTop: '24px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div
                  style={{
                    width: '4px',
                    height: '4px',
                    backgroundColor: '#b9bbbe',
                    borderRadius: '50px',
                    marginRight: '8px',
                  }}
                />
                <div style={{ display: 'flex', fontFamily: 'Pretendard Variable', fontSize: '14px', color: '#424242' }}>
                  <div style={{ fontWeight: 'bold' }}>2인</div>
                  <div style={{ marginLeft: '4px' }}>그룹 할인</div>
                </div>
                <div
                  style={{
                    marginLeft: '12px',
                    marginRight: '12px',
                    flex: 1,
                    borderBottom: '1px dashed #dadcdf',
                  }}
                />
                <div
                  style={{
                    fontSize: '14px',
                    color: '#FF3D8F',
                    fontWeight: 'bold',
                  }}>
                  20%
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: '8px',
                }}>
                <div
                  style={{
                    width: '4px',
                    height: '4px',
                    backgroundColor: '#b9bbbe',
                    borderRadius: 50,
                    marginRight: '8px',
                  }}
                />
                <div style={{ display: 'flex', fontFamily: 'Pretendard Variable', fontSize: '14px', color: '#424242' }}>
                  <div style={{ fontWeight: 'bold' }}>3인</div>
                  <div style={{ marginLeft: '4px' }}>그룹 할인</div>
                </div>
                <div
                  style={{
                    marginLeft: '12px',
                    marginRight: '12px',
                    flex: 1,
                    borderBottom: '1px dashed #dadcdf',
                  }}
                />
                <div
                  style={{
                    fontSize: '14px',
                    color: '#FF3D8F',
                    fontWeight: 'bold',
                  }}>
                  30%
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: '8px',
                }}>
                <div
                  style={{
                    width: '4px',
                    height: '4px',
                    backgroundColor: '#b9bbbe',
                    borderRadius: '50px',
                    marginRight: '8px',
                  }}
                />
                <div style={{ display: 'flex', fontFamily: 'Pretendard Variable', fontSize: '14px', color: '#424242' }}>
                  <div style={{ fontWeight: 'bold' }}>4인 이상</div>
                  <div style={{ marginLeft: '4px' }}>그룹 할인</div>
                </div>
                <div
                  style={{
                    marginLeft: '12px',
                    marginRight: '12px',
                    flex: 1,
                    borderBottom: '1px dashed #dadcdf',
                  }}
                />
                <div
                  style={{
                    fontSize: '14px',
                    color: '#FF3D8F',
                    fontWeight: 'bold',
                  }}>
                  40%
                </div>
              </div>
              <div
                style={{
                  color: 'var(--Neutral-70, #808387)',
                  textAlign: 'center',
                  fontFamily: '"Pretendard Variable"',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                  padding: '12px 16px',
                  borderRadius: '8px',
                  backgroundColor: '#F8F9FC',
                  marginTop: '20px',
                }}>
                자세한 금액은 상담을 통해 확인해 주세요.
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const TutorInformation: React.FC<{ data: Product }> = (props) => {
  const data = props.data;
  if (data.saleType !== 'class') {
    return <></>;
  }

  return (
    <div style={{ paddingLeft: '16px', paddingRight: '16px', marginTop: '32px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div className="product-detail-title">
          <div>{data?.sellerName}</div>
          <div>튜터입니다.</div>
        </div>
        <div>
          <img
            style={{
              width: '52px',
              height: '52px',
              borderRadius: '35px',
              backgroundColor: '#FFFFFF',
              border: '1px solid rgba(0,0,0,0.04)',
              overflow: 'hidden',
              objectFit: 'cover',
            }}
            src={data?.sellerProfileImage ? cdnImageUrl(data?.sellerProfileImage) : TutorDefaultAvatar}
            alt=""
          />
        </div>
      </div>
      <div
        style={{
          width: '100%',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#424242',
          marginTop: '12px',
          whiteSpace: 'pre-wrap',
        }}>
        {data?.sellerIntroduction || '반갑습니다, 부모님. ' + data?.sellerName + ' 튜터 입니다.'}
      </div>
      <button
        className={'link-button'}
        style={{
          display: 'flex',
          borderRadius: '8px',
          marginTop: '16px',
          border: '1px solid #dadcdf',
          alignItems: 'center',
          justifyContent: 'center',
          height: '36px',
          width: '100%',
        }}
        onClick={() => {}}>
        <div style={{ fontSize: '14px', fontWeight: '500', color: '#424242' }}>프로필 보기</div>
      </button>
    </div>
  );
};

const ClassCaution: React.FC = () => {
  return (
    <div
      style={{
        marginTop: '24px',
        marginBottom: '24px',
        marginLeft: '16px',
        marginRight: '16px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '12px',
        backgroundColor: '#fff5f5',
        padding: '24px',
      }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <img style={{ width: '20px', height: '20px', marginRight: '6px' }} src={ExclamationIcon} alt="" />
        <div
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#f03f45',
          }}>
          개인거래 주의 안내
        </div>
      </div>
      <div
        style={{
          marginTop: '16px',
          fontSize: '14px',
          lineHeight: '21px',
          color: '#66686b',
          fontWeight: 'bold',
          flexWrap: 'wrap',
          textAlign: 'center',
        }}>
        채팅을 통해
        <span style={{ color: '#f03f45', fontWeight: 'bold' }}>개인번호 요청</span> 및
        <span style={{ color: '#f03f45', fontWeight: 'bold' }}>개인 거래 요구,</span>
        <br />
        <span style={{ color: '#f03f45', fontWeight: 'bold' }}>SNS로 유도</span>
        하는 경우 결제하지 마시고
        <br />
        <span
          style={{
            color: '#0180ef',
            fontWeight: 'bold',
            textDecorationLine: 'underline',
          }}
          onClick={() => {
            window.open('https://pf.kakao.com/_dxjxixaC/chat');
          }}>
          아이고고 고객센터
        </span>
        로 문의해 주시기 바랍니다.
        <br />
        (위반 사례 발견 시, 서비스 이용 제재조치)
      </div>
    </div>
  );
};

const ClassIntroduction: React.FC<{ data: ProductEditModel }> = (props) => {
  const data = props.data;
  const detailImage =
    data?.classIntroductionImageTemp ||
    data.classIntroductionImage ||
    (data?.detailImagesTemp || data?.detailImages)?.[0];
  return (
    <div style={{ marginTop: '16px', paddingLeft: '16px', paddingRight: '16px' }}>
      <img style={{ width: '55px', height: '12px', marginBottom: '10px' }} src={AboutCaptionImage} alt="" />
      <div
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          lineHeight: '26px',
          letterSpacing: -0.6,
        }}>
        클래스를 소개합니다
      </div>

      {detailImage && (
        <div style={{ marginTop: '24px' }}>
          <img
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
            src={cdnImageUrl(detailImage)}
            alt=""
          />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          position: 'relative',
          marginTop: '46px',
          marginBottom: '40px',
          borderRadius: '8px',
          paddingTop: '28px',
          paddingBottom: '20px',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingLeft: '20px',
          paddingRight: '20px',
          border: '1px solid #e8eaed',
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '60px',
            backgroundColor: '#ffffff',
            position: 'absolute',
            top: '-8px',
          }}>
          <img style={{ width: '22px', height: '16px' }} src={QuotesIcon} alt="" />
        </div>
        <div
          style={{
            display: 'flex',
            fontSize: '18px',
            lineHeight: '28px',
            color: '#424242',
            textAlign: 'center',
            flexWrap: 'wrap',
          }}>
          {data?.title}
        </div>
        <div style={{ fontSize: '14px', color: '#808387', marginTop: '8px' }}>by. {data?.sellerName}</div>
      </div>

      {(data?.details?.introductions || []).map((item: { title?: string; description?: string }, index: number) => {
        return (
          <div
            key={`intro-${index}`}
            style={{
              marginBottom: data?.details?.introductions?.length === index + 1 ? 0 : '40px',
            }}>
            {item.title && item.title !== '' && (
              <div
                style={{
                  fontSize: '18px',
                  lineHeight: '24px',
                  color: '#242424',
                  fontWeight: 'bold',
                  marginBottom: '12px',
                }}>
                {item.title}
              </div>
            )}
            {item.description && item.description !== '' && (
              <div
                style={{
                  fontSize: '16px',
                  color: '#424242',
                  lineHeight: '28px',
                  letterSpacing: -0.4,
                }}>
                {item.description.split('\n').map((line, index2) => {
                  if (line && line !== '' && line[0] === '-') {
                    line = line.replace('-', '• ');
                  }

                  return (
                    <div key={`description-${index2}`} style={{ whiteSpace: 'pre-wrap' }}>
                      {line}
                      {(item?.description || '').split('\n').length - 1 === index2 ? '' : '\n'}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const ClassRecommendation: React.FC<{ data: ProductEditModel }> = (props) => {
  const data = props.data;
  const recommendItemList: string[] = (data?.details?.recommendText || '')
    .split('\n')
    .map((line) => {
      return line.trim();
    })
    .filter((line: string) => line.length > 0);

  return (
    <div style={{ marginTop: '80px', paddingLeft: '16px', paddingRight: '16px' }}>
      <img style={{ width: '103px', height: '12px', marginBottom: '10px' }} src={RecommendCaptionImage} alt="" />
      <div
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          lineHeight: '26px',
          letterSpacing: -0.6,
        }}>
        <div style={{ fontWeight: 'bold' }}>이런 친구에게 추천해요</div>
      </div>

      <div style={{ paddingTop: '16px', paddingBottom: '7px' }}>
        {recommendItemList.map((line, index) => {
          if (line[0] === '-') {
            line = line.replace(' - ', '').replace('- ', '').replace('-', '').replace(/^ /, '');
          }

          return (
            <div
              key={`recommend-${index}`}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '12px',
              }}>
              <div
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  backgroundColor: '#242424',
                  flexShrink: 0,
                }}>
                <div
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#ffffff',
                  }}>
                  {index + 1}
                </div>
              </div>
              <div
                style={{
                  marginLeft: '12px',
                  border: '1px solid #e8eaed',
                  borderRadius: '8px',
                  width: '100%',
                  padding: '16px',
                }}>
                <div
                  style={{
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#424242',
                  }}>
                  {line}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ClassPreview: React.FC<{ data: ProductEditModel }> = (props) => {
  const data = props.data;
  return (
    <div style={{ marginTop: '80px', paddingLeft: '16px', paddingRight: '16px' }}>
      <img style={{ width: '64px', height: '12px', marginBottom: '10px' }} src={HowToCaptionImage} alt="" />
      <div
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          lineHeight: '26px',
          letterSpacing: -0.6,
        }}>
        클래스는 이렇게 진행돼요
      </div>

      <div style={{ marginTop: 0 }}>
        {(data.details?.concepts || []).map((item, index) => {
          const images = item.imagesTemp || item.images || [];
          return (
            <div
              key={`concept-${index}`}
              style={{
                marginTop: index === 0 ? '28px' : '40px',
                display: 'flex',
                flexDirection: 'row',
              }}>
              <div>
                {images.length > 0 && (
                  <div style={{ marginBottom: '20px' }}>
                    {images.map((cur, index2) => {
                      return (
                        <div key={`item-image-${index}-${index2}`}>
                          <img
                            style={{
                              width: '100%',
                              marginBottom: images!.length - 1 === index2 ? 0 : '4px',
                              objectFit: 'cover',
                            }}
                            src={cdnImageUrl(cur as string)}
                            alt=""
                          />
                        </div>
                      );
                    })}
                  </div>
                )}

                {item.conceptTitle && (
                  <div
                    style={{
                      fontSize: '18px',
                      color: '#242424',
                      lineHeight: '24px',
                      fontWeight: 'bold',
                      letterSpacing: -0.2,
                      marginBottom: '12px',
                    }}>
                    {item.conceptTitle}
                  </div>
                )}
                {item.conceptData && (
                  <div
                    style={{
                      width: '100%',
                      flexWrap: 'wrap',
                      marginTop: 0,
                      fontSize: '16px',
                      color: '#424242',
                      lineHeight: '28px',
                      letterSpacing: -0.4,
                      whiteSpace: 'pre-wrap',
                    }}>
                    {item.conceptData.split('\n').map((line, index2) => {
                      if (line && line !== '' && line[0] === '-') {
                        line = line.replace('-', '• ');
                      }

                      return (
                        <div key={`concept-data-${index}-${index2}`}>
                          {line}
                          {item.conceptData && item.conceptData.split('\n').length - 1 === index2 ? '' : '\n'}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ClassPreparations: React.FC<{ data: ProductEditModel }> = (props) => {
  const data = props.data;
  return (
    <div style={{ marginTop: '80px', paddingLeft: '16px', paddingRight: '16px' }}>
      <img style={{ width: '53px', height: '12px', marginBottom: '10px' }} src={NeedsCaptionImage} alt="" />
      <div
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          lineHeight: '26px',
          letterSpacing: -0.6,
        }}>
        클래스 준비물
      </div>
      <div style={{ marginTop: '16px', whiteSpace: 'pre-wrap' }}>
        {((data.details?.preparationText || '').split('\n') || []).map((line, index) => {
          if (line[0] === '-') {
            line = line.replace('-', '').trim();
          }

          if (line.length === 0) {
            return <></>;
          }

          return (
            <div
              key={`preparation-${index}`}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '12px',
              }}>
              <div
                style={{
                  alignSelf: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  backgroundColor: '#242424',
                  flexShrink: 0,
                }}>
                <div
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#ffffff',
                  }}>
                  {index + 1}
                </div>
              </div>
              <div
                style={{
                  marginLeft: '12px',
                  border: '1px solid #e8eaed',
                  borderRadius: '8px',
                  padding: '16px',
                  width: '100%',
                }}>
                <div
                  style={{
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#424242',
                  }}>
                  {line}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// const RecommendedProducts: React.FC<{ productId: string }> = (props) => {
//   const { productId } = props;

//   const recommendProductsQuery = useQuery({
//     queryKey: [`recommend-items-${productId}`],
//     queryFn: () => api.getRecommendProducts(productId!),
//     enabled: !!productId,
//   });

//   const recommendProducts = recommendProductsQuery.data?.data || [];
//   const itemWidth = 428 / 2 - 48;

//   if (!recommendProducts || recommendProducts.length === 0) {
//     return <></>;
//   }

//   return (
//     <div>
//       <div style={{ marginTop: '32px', paddingLeft: '16px', paddingRight: '16px' }}>
//         <div className="product-detail-title">함께 보면 좋은 클래스</div>
//         <div style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
//           <Carousel
//             options={{
//               dragFree: true,
//               align: 'start',
//             }}>
//             {recommendProducts.map((item: any, index: number) => {
//               return (
//                 <div
//                   key={`class-general-item-${index}`}
//                   className={'embla__slide'}
//                   style={{ flex: '0 0 auto', width: itemWidth, cursor: 'pointer' }}>
//                   <ProductItem
//                     onClick={() => {}}
//                     item={item}
//                     width={itemWidth}
//                     style={{ marginLeft: '12px' }}></ProductItem>
//                 </div>
//               );
//             })}
//           </Carousel>
//         </div>
//       </div>
//     </div>
//   );
// };

const ClassCautionBeforeApply: React.FC<{ onClick?: () => void }> = (props) => {
  return (
    <div
      style={{
        marginTop: '32px',
        paddingLeft: '16px',
        paddingRight: '16px',
        marginBottom: '40px',
      }}>
      <div
        style={{
          fontSize: '20px',
          fontWeight: 'bold',
          lineHeight: '26px',
          letterSpacing: -0.6,
        }}>
        신청 전 유의사항
      </div>
      <button className="link-button" onClick={props.onClick} style={{ width: '100%', textAlign: 'left' }}>
        <div
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: '#ffffff',
            marginTop: '8px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div
            style={{
              alignSelf: 'center',
              fontSize: '14px',
              color: '#66686b',
              lineHeight: '21px',
              letterSpacing: -0.4,
              width: '100%',
            }}>
            <span style={{ fontWeight: 'normal' }}>수업료 결제, 스케줄 변경, 환불 정책 </span>
            등의 유의사항을 확인해주세요.
          </div>
          <img src={ArrowRightIcon} style={{ alignSelf: 'center', width: '20px', height: '20px' }} alt="" />
        </div>
      </button>
    </div>
  );
};

const TabBar: React.FC<{
  saleType?: string;
  scrollFocusOn: string;
  onClickDetail: () => void;
  onClickEtc: () => void;
}> = (props) => {
  const { scrollFocusOn, onClickDetail, onClickEtc } = props;
  return (
    <div className="product-tab-bar" style={{ position: 'sticky', top: 0, backgroundColor: '#ffffff', zIndex: 10 }}>
      <div
        style={{
          display: 'flex',
          height: '48px',
          borderBottom: '1px solid #eff1f4',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          backgroundColor: '#ffffff',
        }}>
        <div
          style={{
            display: 'flex',
            borderBottom: scrollFocusOn === 'detail' ? '3px solid black' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
            height: '48px',
            position: 'relative',
            paddingTop: scrollFocusOn === 'detail' ? 4 : 0,
            cursor: 'pointer',
          }}
          onClick={() => {
            onClickDetail();
          }}>
          <div
            style={{
              color: scrollFocusOn === 'detail' ? '#242424' : '#b9bbbe',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: 'bold',
            }}>
            상세정보
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            borderBottom: scrollFocusOn === 'etc' ? '3px solid black' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
            height: '48px',
            paddingTop: scrollFocusOn === 'policy' ? 4 : 0,
            cursor: 'pointer',
          }}
          onClick={() => {
            onClickEtc();
          }}>
          <div
            style={{
              color: scrollFocusOn === 'etc' ? '#242424' : '#b9bbbe',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: 'bold',
            }}>
            {'규칙/정책'}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProductDetailView: React.FC<{ product: ProductEditModel }> = (props) => {
  const { pathname } = useLocation();
  const [headerOnImage, setHeaderOnImage] = useState<boolean>(true);
  const [scrollFocusOn, setScrollFocusOn] = useState<string>('detail');
  const [showCautions, setShowCautions] = useState<boolean>(false);
  const productDetailInfoRef = useRef<any>();
  const productEtcInfoRef = useRef<any>();
  const scrollTopRef = useRef<any>();

  useEffect(() => {
    if (scrollTopRef.current) {
      scrollTopRef.current.scrollIntoView({ block: 'start' });
    }
  }, [pathname]);

  const data = props.product;

  useEffect(() => {
    document.title = data?.title ? `아이고고 | ${data.title}` : '아이고고';
    return () => {
      document.title = '아이고고 - 우리아이 맞춤 키즈클래스 플랫폼';
    };
  }, [data?.title]);

  return (
    <div>
      <AppServiceCautionsModal
        open={showCautions}
        onCancel={() => {
          setShowCautions(false);
        }}></AppServiceCautionsModal>
      <div
        style={{
          // position: 'sticky',
          // zIndex: 1,
          // top: 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          height: HEADER_HEIGHT,
          backgroundColor: headerOnImage ? 'transparent' : '#ffffff',
          borderBottom: headerOnImage ? 'none' : '1px solid #eff1f4',
        }}></div>
      <div style={{ marginTop: '-56px', height: 'calc(100dvh - 44px)', overflow: 'hidden' }}>
        <OverlayScrollbarsComponent
          defer
          options={{
            scrollbars: { autoHide: 'scroll' },
            overflow: {
              x: 'hidden',
            },
          }}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 0,
            position: 'relative',
            height: 'calc(100dvh - 84px)',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
          events={{
            scroll: (instance: OverlayScrollbars, event: any) => {
              const scrollTop = event.target?.scrollTop;
              if (instance) {
                if (scrollTop > 505) {
                  setHeaderOnImage(false);
                } else {
                  setHeaderOnImage(true);
                }
              }
            },
          }}>
          <div className="class-detail-container" style={{ paddingBottom: '40px' }}>
            <div ref={scrollTopRef}></div>
            <DetailImages
              imageUrls={data?.detailImagesTemp || (data?.detailImages as string[])}
              soldout={data?.soldout}></DetailImages>
            {data && <ProductSummary data={data} />}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                borderTop: '1px solid #EFF1F4',
              }}>
              <button
                className="link-button"
                style={{ height: '60px', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                <ShareIcon />
                <span
                  style={{
                    marginLeft: '4px',
                    color: 'var(--Neutral-100, #242424)',
                    textAlign: 'right',
                    fontFamily: '"Pretendard Variable"',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                  }}>
                  공유
                </span>
              </button>
              <div style={{ width: '1px', height: '20px', backgroundColor: '#EFF1F4', flexShrink: 0 }}></div>
              <button
                className="link-button"
                style={{ height: '60px', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                <FavoritedIcon />
                <span
                  style={{
                    marginLeft: '4px',
                    color: 'var(--Neutral-100, #242424)',
                    textAlign: 'right',
                    fontFamily: '"Pretendard Variable"',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                  }}>
                  찜
                </span>
              </button>
            </div>
            <DividerBold />
            {data && <ProductInformation data={data} />}
            <DividerBold />
            {data && <TutorInformation data={data} />}
            <TabBar
              saleType={data?.saleType}
              scrollFocusOn={scrollFocusOn}
              onClickDetail={() => {
                if (productDetailInfoRef.current) {
                  productDetailInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
              }}
              onClickEtc={() => {
                if (productEtcInfoRef.current) {
                  productEtcInfoRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start',
                  });
                }
              }}
            />
            <div id="product-detail-area" ref={productDetailInfoRef}>
              {data && data.saleType === 'class' && <ClassCaution />}
              {/* {data &&
                (data.detailType === 'html' ||
                  (!data.detailType && data.saleType === 'commerce' && data?.detailHtml)) && (
                  <CommerceDetailInformation detailHtml={data?.detailHtml} />
                )} */}
              {data && (data.detailType === 'template' || (!data.detailType && data.saleType === 'class')) && (
                <>
                  <ClassIntroduction data={data} />
                  <ClassRecommendation data={data} />
                  <ClassPreview data={data} />
                  <ClassPreparations data={data} />
                </>
              )}
              {data && data.detailType === 'images' && (
                <DetailBodyImages images={data.detailBodyImagesTemp || data.detailBodyImages || []} />
              )}

              <DividerBold style={{ marginTop: '20px' }} />
            </div>
            <VisibilitySensor
              partialVisibility
              onChange={(isVisible: boolean) => {
                setScrollFocusOn(isVisible ? 'etc' : 'detail');
              }}>
              <div id="product-etc-area" ref={productEtcInfoRef}>
                {data && data.saleType === 'commerce' && data.shippingProduct && <ShippingInfo data={data} />}
                {data && data.saleType === 'commerce' && <Divider />}
                {data && <ProductRefundPolicy data={data} />}
                {data && data.saleType === 'class' && <Divider style={{ paddingTop: '20px' }} />}
                {data && data.saleType === 'class' && (
                  <ClassCautionBeforeApply
                    onClick={() => {
                      setShowCautions(true);
                    }}
                  />
                )}
                {data && data.saleType === 'commerce' && (
                  <div style={{ padding: '20px' }}>
                    <button
                      className="link-button"
                      style={{
                        borderRadius: '8px',
                        border: '1px solid var(--Neutral-30, #E8EAED)',
                        background: 'var(--White-100, #FFF)',
                        color: 'var(--Neutral-90, #424242)',
                        textAlign: 'center',
                        fontFamily: 'Pretendard',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        height: '44px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}>
                      판매자 문의
                    </button>
                  </div>
                )}
                <DividerBold style={{ marginTop: '20px' }} />
              </div>
            </VisibilitySensor>
          </div>
        </OverlayScrollbarsComponent>
      </div>
    </div>
  );
};

export default ProductDetailView;
