import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { User } from '@models/auth';

export enum LoginStatus {
  LOGIN = 'login',
  ANONYMOUS = 'anonymous',
  READY = 'ready',
  CHECKING = 'checking',
}

interface AuthStore {
  status: LoginStatus;
  user?: User | null;
  setUser: (user?: User) => void;
}

export const useAuthStore = create(
  persist<AuthStore>(
    (set) => ({
      status: LoginStatus.READY,
      user: null,
      setUser: (user?: User) => {
        set((state) => {
          const newStatus = user ? LoginStatus.LOGIN : LoginStatus.ANONYMOUS;
          return { ...state, user: user, status: newStatus };
        });
      },
    }),
    {
      name: 'igogo-partner-user',
    },
  ),
);
