export interface SalesChannelOrderRefund {
  receiptUrl?: string;
  createdAt?: Date;
  amount?: number;
  billingAmount?: number;
  data?: any;
}

export enum SalesChannelsType {
  NAVER_STORE = 'naver-store',
  KAKAO_RESERVATION = 'kakao-reservation',
  INBOUND_APP = 'inbound-app',
  INBOUND_NAVER = 'inbound-naver',
}

export interface SalesChannelOrder {
  _id: string;
  uid: string;
  buyer: Buyer;
  channel: SalesChannelsType;
  createdAt: string;
  memo: Memo;
  origin: Origin;
  payment: Payment;
  purchaseNotificationDate: Date;
  refundNotificationDate: Date;
  refund?: SalesChannelOrderRefund;
  plannedDate?: Date;
  plannedDates?: Date[];
  product: Product;
  purchase?: Purchase;
  purchases?: Purchase[];
  receiver: Receiver;
  seller: Seller;
  statuses: Statuses;
  updatedAt: string;
  sellerUser: SellerUser;
}

export interface Buyer {
  name: string;
  phone: string;
  nId: string;
}

export interface Memo {
  payment: string;
  shipping?: string;
  refund?: string;
  qna?: string;
}

export interface Origin {
  orderId: string;
  buyerName: string;
  receiverName: string;
  productName: string;
  option: string;
  quantity: number;
  paidAt: number;
  orderStatus: string;
  device: string;
  buyerId: string;
  shippingMethodRequested: string;
  orderNumber: string;
  productNumber: string;
  shippingMethod: string;
  shippingAgent: any;
  shippingInvoiceNumber: any;
  optionFee: number;
  shippingDate: number;
  orderDetailStatus: string;
  productType: string;
  productPrice: number;
  productDiscountPriceTotal: number;
  productDiscountPrice: number;
  orderPrice: number;
  gift: any;
  orderCheckDate: number;
  shippingDeadlineDate: number;
  shippingProceedDate: any;
  shippingInvoicePrintDate: any;
  shippingFeeFormType: any;
  shippingFeeNumber: any;
  shippingFeeType: any;
  shippingFeeTotal: number;
  shippingFeeAdditional: any;
  shippingFeedDiscount: number;
  sellerProductCode: any;
  sellerCode1: any;
  sellerCode2: any;
  receiverPhone1: string;
  receiverPhone2: any;
  address: any;
  addressBase: any;
  addressDetail: any;
  buyerPhone: string;
  addressPostCode: any;
  deliveryMemo: any;
  shippingLocation: any;
  paymentMethod: string;
  paymentCommissionType: string;
  paymentCommissionBillingType: string;
  naverPayOrderCommission: number;
  naverPayIncomeCommission: number;
  expectedSettlementAmount: number;
  referer: string;
  personalClearanceCode: any;
  orderedAt: number;
  subscribeAppliedCount: number;
  subscribeProcessCount: number;
  shippingReceiptDate: any;
}

export interface Payment {
  method: string;
  amount: number;
  billingAmount: number;
  paidAt: string;
}

export interface Product {
  id?: string;
  name: string;
}

export interface Purchase {
  name: string;
  fee: number;
  count: number;
  uid?: string;
  status?: string;
  date?: string;
  time?: string;
}

export interface Receiver {
  name: string;
  phone: string;
  phone2: any;
}

export interface Seller {
  id?: string;
  name?: string;
  phone?: string;
}

export interface Statuses {
  payment: string;
  refund: string;
  settlement: string;
}

export interface SellerUser {
  id: string;
  loginType: string;
  role: string;
  profile: Profile;
}

export interface Profile {
  name: string;
  phone: string;
  customerServicePhone: string;
}
