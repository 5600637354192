import React from 'react';
import { Modal } from 'antd';
import { ReactComponent as CloseIcon } from '@assets/images/x-dark.svg';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';

export const OkCancelDialog: React.FC = () => {
  const { show, data, close } = useOkCancelDialog();
  return (
    <Modal
      className="no-radius-modal"
      width={440}
      centered
      open={show}
      closable={false}
      zIndex={99999}
      onCancel={() => {
        close();
      }}
      footer={null}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px 24px',
        }}>
        <div>
          <CloseIcon style={{ float: 'right', cursor: 'pointer' }} onClick={() => close()} />
        </div>
        {data?.customTitle ? (
          <div>{data.customTitle}</div>
        ) : (
          <div
            style={{
              whiteSpace: 'pre-line',
              color: 'var(--Neutral-100, #242424)',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '24px',
            }}>
            {data?.title}
          </div>
        )}

        <div
          style={{
            marginTop: data?.title ? '8px' : 0,
            marginBottom: '32px',
            color: 'var(--Neutral-80, #424242)',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
            whiteSpace: 'pre-line',
          }}>
          {data?.content}
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
          }}>
          {data?.type !== 'ok' && (
            <button
              className="pa-button white wxl"
              onClick={() => {
                data && data.onCancel && data.onCancel();
                close();
              }}>
              <div style={{ color: '#808387', fontSize: '16px', fontWeight: 'bold' }}>{data?.cancelText || '취소'}</div>
            </button>
          )}
          <button
            className="pa-button black wxl"
            onClick={() => {
              data && data.onConfirm && data.onConfirm();
              close();
            }}>
            <div style={{ color: '#FFFFFF', fontSize: '16px', fontWeight: 'bold' }}>{data?.confirmText || '확인'}</div>
          </button>
          {data?.type === 'ok_all_cancel' && (
            <button
              className="pa-button black wxl"
              onClick={() => {
                data && data.onConfirmAll && data.onConfirmAll();
                close();
              }}>
              <div style={{ color: '#FFFFFF', fontSize: '16px', fontWeight: 'bold' }}>
                {data?.confirmAllText || '확인'}
              </div>
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OkCancelDialog;
