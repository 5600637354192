import { PhoneValidatorResponse } from '@models/common';
import { UrlPreviewData } from '@models/user-contents';
import { LastCheckDates } from '@stores/appUserSessionStore';
import { apiClient } from '.';

export const requestPhoneValidate = async (phoneNumber: string) => {
  return apiClient.post<PhoneValidatorResponse>(`/app/v1/phone`, { phoneNumber });
};

export const validatePhone = async (data: { phoneNumber: string; codeNumber: number }) => {
  return apiClient.post<{ resultMessage: string }>(`/app/v1/phone/validation`, {
    data,
  });
};

export const getUrlPreview = async (url: string) => {
  return apiClient.get<UrlPreviewData>(`/preview`, { params: { url } });
};

export const getNewUpdatedCounts = async (data: LastCheckDates) => {
  return await apiClient.get<{ paymentStatements: number; saleChannelOrders: number; chatMessages: number }>(
    `/partner/v1/new-counts`,
    { params: data },
  );
};
