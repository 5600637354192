import React from 'react';
import HeaderCloseIcon from '@assets/images/productdetailview/icon-navi-close.png';
import { NavBackIcon } from '@components/common/Icons';

export interface AppPreviewModalPros {
  onLeftNaviButtonClick?: (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => void;
  onRightNaviButtonClick?: (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => void;
  title?: any;
  titleStyle?: any;
  headerStyle?: any;
  children?: any;
  footer?: any;
  open?: boolean;
  height?: number | string;
  leftNaviButtonIcon?: 'back' | 'close';
  hideLeftNaviButton?: boolean;
  hideRightNaviButton?: boolean;
  hideHeader?: boolean;
  rightNaviButton?: any;
}

export const AppPreviewModal: React.FC<AppPreviewModalPros> = (props) => {
  const { open } = props;
  return (
    <div
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        props.onRightNaviButtonClick && props.onRightNaviButtonClick(e);
      }}
      style={{
        transition: 'opacity 0.3s linear 0s',
        opacity: open ? 1 : 0,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        position: 'absolute',
        width: open ? '100%' : 0,
        height: props.height || '100%',
        backgroundColor: 'rgba(0,0,0,0.4)',
        zIndex: 99999,
        overflow: 'hidden',
      }}>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{
          position: 'absolute',
          opacity: open ? 1 : 0,
          height: '100%',
          width: '480px',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: 'auto',
          backgroundColor: 'white',
          borderRadius: '8px 8px 0 0',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          ...props.headerStyle,
        }}>
        {!props.hideHeader && (
          <div
            id="bottom-modal-header"
            style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '52px', flexShrink: 0 }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div style={{ minWidth: '52px' }}>
                {!props.hideLeftNaviButton && (
                  <button
                    className="link-button"
                    style={{ padding: '12px', cursor: 'pointer' }}
                    onClick={(e) => {
                      props.onLeftNaviButtonClick && props.onLeftNaviButtonClick(e);
                    }}>
                    {props.leftNaviButtonIcon === 'close' ? (
                      <img style={{ width: '24px', height: '24px' }} src={HeaderCloseIcon} alt="" />
                    ) : (
                      <NavBackIcon color={'#242424'} />
                    )}
                  </button>
                )}
              </div>
              <div style={{ alignSelf: 'center', fontSize: '17px', ...props.titleStyle }}>{props.title}</div>
              <div style={{ minWidth: '52px' }}>
                {!props.hideRightNaviButton && (
                  <button
                    className="link-button"
                    style={{ padding: '12px', cursor: 'pointer' }}
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      props.onRightNaviButtonClick && props.onRightNaviButtonClick(e);
                    }}>
                    {props.rightNaviButton ? (
                      props.rightNaviButton
                    ) : (
                      <img style={{ width: '24px', height: '24px' }} src={HeaderCloseIcon} alt="" />
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        <div
          id="bottom-modal-body"
          style={{ height: `calc(100% - ${props.hideHeader ? 0 : 52}px - ${props.footer ? 60 : 0}px)` }}>
          {props.children}
        </div>
        {props.footer && (
          <div id="bottom-modal-footer" style={{ alignSelf: 'flex-end', width: '100%', height: '60px', flexShrink: 0 }}>
            {props.footer}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppPreviewModal;
