import { create } from 'zustand';

export interface CommentModalData {
  title?: string;
  subTitle?: string;
  comment?: string;
  content?: any;
}
interface CommentModal {
  show?: boolean;
  data?: CommentModalData;
  open: (data: CommentModalData) => void;
  close: () => void;
}

const useCommentModalStore = create<CommentModal>((set) => ({
  show: false,
  data: undefined,
  close: () => set({ show: false, data: undefined }),
  open: (data) => set({ data, show: true }),
}));

export default useCommentModalStore;
