export enum Roles {
  ADMIN = 'admin',
  SUBADMIN = 'subadmin',
  SALEADMIN = 'saleadmin',
  PARENT = 'parent',
  TUTOR = 'teacher',
  SELLER = 'seller',
  PARTNER = 'partner',
  EDITOR = 'editor',
  MEMBERSHIP_PARTNER = 'membership-partner',
}

export const ROLE_LABELS: { [key: string]: string } = {
  [Roles.ADMIN]: '어드민',
  [Roles.SUBADMIN]: '서브어드민',
  [Roles.SALEADMIN]: '세일어드민',
  [Roles.PARENT]: '부모',
  [Roles.TUTOR]: '튜터',
  [Roles.SELLER]: '판매자',
  [Roles.PARTNER]: '파트너',
  [Roles.EDITOR]: '에디터',
  [Roles.MEMBERSHIP_PARTNER]: '멤버십 파트너',
};

export enum TutorContractType {
  CONTRACT_TYPE_BUSINESS = 'business',
  CONTRACT_TYPE_FREELANCER = 'freelancer',
  CONTRACT_TYPE_STUDENT = 'student',
}
