export const PRODUCT_ACTIVITY_TYPES: { [key: string]: string } = {
  home: '가정방문',
  studio: '스튜디오',
  experiential: '체험수업',
  online: '비대면',
  digital: '디지털',
  shipping: '배송상품',
};

export const CLASS_PRODUCT_ACTIVITY_TYPES: { [key: string]: string } = {
  home: '가정방문',
  studio: '스튜디오',
  experiential: '체험수업',
  online: '비대면',
  digital: '디지털',
};

export const COMMERCE_PRODUCT_ACTIVITY_TYPES: { [key: string]: string } = {
  studio: '스튜디오',
  experiential: '체험수업',
  digital: '디지털',
  shipping: '배송상품',
  // online: '비대면',
  // ticket: '티켓상품',
};

export const PRODUCT_CATEGORIES: { [key: string]: string[] } = {
  신체: ['맨몸', '교구', '발레', '댄스'],
  예술: ['음악', '미술', '퍼포먼스'],
  STEAM: ['과학', '코딩', '엔지니어링', '브레인', '수학'],
  언어: ['한글', '영어', '중국어', '기타 언어'],
  특별활동: ['역사', '스피치', '요리', '자연', '심리', '성교육', '기타 활동'],
};

export const PRODUCT_AGE_RANGE: { [key: string]: string } = {
  '0': '0세',
  '1': '1세',
  '2': '2세',
  '3': '3세',
  '4': '4세',
  '5': '5세',
  '6': '6세',
  '7': '7세',
  '8': '8세',
  '9': '9세',
  '10': '10세',
};

export const EXAMPLE_CAUTION = `해당 내용은 아이고고의 일괄적인 환불정책이 아닌 단순 ‘예시’ 텍스트입니다.
파트너님의 의도와 방향성에 맞게 수정하여 사용해주세요.`;

export const CLASS_REFUND_POLICY = `수업 예정일 2일 전 17시까지 취소 요청시 전액 환불
환불 가능 기간 경과 후에는 환불 불가
환불 가능 기간 중에는 수업일 변경 요청 가능
환불 가능 기간 경과 후 수업일 연장 요청 1회 가능
정기 다회차 클래스 예약시, 수강 횟수가 1/2를 넘어가면 개인 사유로 인한 환불 불가
`;

export const COMMERCE_REFUND_POLICY = `• 수업 4일 전까지 : 전액 환불
• 수업 3일 전 : 50% 환불
• 수업 2일 전 ~ 당일 : 환불 불가
- 최소 인원(2인) 미달로 폐강 시 전액 환불됩니다.
- 노쇼, 지각의 경우 보강 및 환불이 불가합니다.
- 천재지변 등 불가항력의 사유 발생 시 전액 환불 됩니다.
- 질병사유(코로나 등)로 참석이 불가할 경우, 수업일 기준 앞뒤 3일 내 관련 자료 제출 시 전액 환불 가능합니다.`;

export const DEFAULT_SHIPPING_POLICY = `- 배송 및 교환, 환불은 아이고고 파트너(판매자)가 직접 진행을 합니다.
- 아래의 방법으로 접수를 해주시면, 해당 판매자 측으로 아이고고에서 연락을 취하여 고객님의 교환 또는 환불이 처리됩니다.
1) 배송 완료일로부터 7일 이내 교환 및 환불 신청 가능합니다.
2) 단순 변심 등 고객사유로 인한 교환의 경우, 왕복 배송비가 발생합니다.
3) 제품 및 포장이 훼손되어 재판매가 불가능할 경우, 교환 및 환불을 거절할 수 있습니다.
`;
