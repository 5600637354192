import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import Autoplay, { AutoplayOptionsType } from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import './Carousel.scss';

export interface CarouselProps {
  children: any;
  options?: EmblaOptionsType;
  autoplay?: boolean;
  autoplayOptions?: AutoplayOptionsType;
  dots?: boolean;
  onChangeSlide?: (slideNo: number) => void;
  scrollToIndex?: number;
  styles?: { containerStyle?: CSSProperties; dotsStyle?: CSSProperties; style?: CSSProperties };
}

export const DotButton: React.FC<any> = (props) => {
  const { children, ...restProps } = props;

  return (
    <button type="button" {...restProps}>
      {children}
    </button>
  );
};

export const Carousel: React.FC<CarouselProps> = (props) => {
  const { children, options, autoplay, dots, scrollToIndex, autoplayOptions } = props;
  const plugins = [];
  if (autoplay) {
    plugins.push(Autoplay(autoplayOptions));
  }
  const [emblaRef, emblaApi] = useEmblaCarousel(options, plugins);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    props.onChangeSlide && props.onChangeSlide(emblaApi.selectedScrollSnap());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (emblaApi) {
      onInit(emblaApi);
      onSelect(emblaApi);
      emblaApi.on('reInit', onInit);
      emblaApi.on('reInit', onSelect);
      emblaApi.on('select', onSelect);
    }

    return () => {
      if (emblaApi) {
        emblaApi.off('reInit', onInit);
        emblaApi.off('reInit', onSelect);
        emblaApi.off('select', onSelect);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emblaApi, onInit, onSelect]);

  useEffect(() => {
    if (scrollToIndex !== undefined) {
      scrollTo(scrollToIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToIndex]);

  //  children: <div className="embla__slide">Slide 1</div>

  return (
    <div className="embla" ref={emblaRef} style={props.styles?.style}>
      <div className="embla__container" style={props.styles?.containerStyle}>
        {children}
      </div>
      {dots && (
        <div className="embla__dots" style={props.styles?.dotsStyle}>
          {scrollSnaps.map((_, index) => {
            return (
              <DotButton
                key={index}
                onClick={() => scrollTo(index)}
                className={'embla__dot'.concat(index === selectedIndex ? ' embla__dot--selected' : '')}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
