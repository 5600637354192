import React, { Suspense, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Layout } from 'antd';
import Sider from '@components/Sider';
import OkCancelDialog from '@components/common/OkCancelDialog';
import { CommentModal } from '@components/modal/CommentModal';
import { ProductPreViewModal } from '@components/modal/ProductPreViewModal';
import { useAppSocket } from '@hooks/appSocket';
import { useAuthentication } from '@hooks/authentication';
import { isPartnerRole } from '@utils/common';
import './App.scss';
import NavBar from './components/NavBar';
import { useViewport } from './hooks/viewport';
import routes from './routes';

const App: React.FC = () => {
  const { isMobile } = useViewport();
  const [showSider, setShowSider] = useState<boolean>();
  const authentication = useAuthentication();
  const location = useLocation();

  useAppSocket();

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <NavBar
        hide={!isPartnerRole(authentication.authStore.user) && location.pathname.split('/')?.[1] !== 'join'}
        onChangeShowSider={(value) => {
          setShowSider(value);
        }}></NavBar>
      <Layout className={`partner-admin-app ant-layout-has-sider ${authentication.authStore.user ? '' : 'login'}`}>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          bodyStyle={{
            textAlign: 'center',
            color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
          }}
          theme="dark"
        />
        <Sider
          hide={
            isMobile
              ? !showSider || !isPartnerRole(authentication.authStore.user)
              : !isPartnerRole(authentication.authStore.user)
          }
        />
        <Layout>
          <Layout.Content
            id="layout-content"
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                {routes.map((route, index) => {
                  return <Route key={`route-${index}`} path={route.path} element={route.element} />;
                })}
              </Routes>
            </Suspense>
            {isPartnerRole(authentication.authStore.user) && (
              <Layout.Footer>Copyright (주)아이들랩. All rights Reserved.</Layout.Footer>
            )}
          </Layout.Content>
        </Layout>
      </Layout>
      <OkCancelDialog />
      <CommentModal />
      <ProductPreViewModal />
    </Suspense>
  );
};

export default App;
