export const STAGE = process.env.REACT_APP_STAGE || 'local';
export const WEB_URL = process.env.REACT_APP_WEB_URL || 'http://localhost:3000';
export const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
export const APP_BASE_PATH = process.env.REACT_APP_APP_BASE_PATH || '';

export const KAKAO_APP_KEY = process.env.REACT_APP_KAKAO_APP_KEY || '';
export const KAKAO_LOGIN_REDIRECT_URL = process.env.REACT_APP_KAKAO_LOGIN_REDIRECT_URL || '';

export const NAVER_APP_ID = process.env.REACT_APP_NAVER_APP_ID || '';
export const NAVER_LOGIN_REDIRECT_URL = process.env.REACT_APP_NAVER_LOGIN_REDIRECT_URL || '';

export const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID || '';
export const APPLE_LOGIN_KEY = process.env.REACT_APP_APPLE_LOGIN_KEY || '';
export const APPLE_LOGIN_REDIRECT_URL = process.env.REACT_APP_APPLE_LOGIN_REDIRECT_URL || '';

export const CDN_BASE_URL = process.env.REACT_APP_CDN_BASE_URL;

export const PRODUCT_SHARE_BASE_URL = process.env.REACT_APP_PRODUCT_SHARE_BASE_URL || '';

export const KAKAO_TUTOR_CENTER_URL = process.env.REACT_APP_KAKAO_TUTOR_CENTER_URL || '';

export const PRODUCT_MAIN_IMAGE_GUIDE_URL = process.env.REACT_APP_PRODUCT_MAIN_IMAGE_GUIDE_URL || '';
export const PRODUCT_OPTION_TEMPLATE_SHEET_URL = process.env.REACT_APP_PRODUCT_OPTION_TEMPLATE_SHEET_URL || '';
