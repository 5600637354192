import { retryLazy } from '@utils/lazyLoad';

export const AuthNaver = retryLazy(() => import('./auth/AuthNaver'));
export const AuthKakao = retryLazy(() => import('./auth/AuthKakao'));
export const AuthApple = retryLazy(() => import('./auth/AuthApple'));

export const AccountPage = retryLazy(() => import('./AccountPage'));
export const DashboardPage = retryLazy(() => import('./DashboardPage'));
export const InvoicePage = retryLazy(() => import('./InvoicePage'));
export const JoinPage = retryLazy(() => import('./JoinPage'));
export const LoginPage = retryLazy(() => import('./LoginPage'));
export const ChannelOrdersPage = retryLazy(() => import('./ChannelOrdersPage'));
export const NoticeDetailPage = retryLazy(() => import('./NoticeDetailPage'));
export const NoticePage = retryLazy(() => import('./NoticePage'));
export const ProductsPage = retryLazy(() => import('./ProductsPage'));
export const ProfileEditPage = retryLazy(() => import('./ProfileEditPage'));
export const ReviewsPage = retryLazy(() => import('./ReviewsPage'));
export const UserContentsPage = retryLazy(() => import('./UserContentsPage'));
export const MembershipUsersPage = retryLazy(() => import('./MembershipUsersPage'));
export const MembershipProductsPage = retryLazy(() => import('./MembershipProductsPage'));

export const SalesHistoryPage = retryLazy(() => import('./SalesHistoryPage'));

export const NotFoundPage = retryLazy(() => import('./NotFound'));
