import moment from 'moment';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { localTimeToUtcISOString } from '@utils/date';
import { getUniqueKeyFromUUID } from '@utils/string';

interface AppUserActionData {
  actionType?: string;
  from?: string;
  to?: string;
  state?: any;
}

export interface LastCheckDates {
  paymentStatementsCheckedAt?: string;
  salesChannelOrdersCheckedAt?: string;
  chatMessagesCheckedAt?: string;
}

interface AppUserSessionStoreProps {
  sessionId: string;
  updatedAt: Date;
  step: number;
  actionData: AppUserActionData;
  lastCheckDates: LastCheckDates;
  getSessionId: () => string;
  setActionData: (data: AppUserActionData) => void;
  setLastCheckDates: (data: LastCheckDates) => void;
}

export const useAppUserSessionStore = create(
  persist<AppUserSessionStoreProps>(
    (set, get) => ({
      sessionId: getUniqueKeyFromUUID(),
      step: 0,
      updatedAt: new Date(),
      actionData: {},
      lastCheckDates: {
        paymentStatementsCheckedAt: localTimeToUtcISOString(moment().subtract(3, 'days').toDate())!,
        salesChannelOrdersCheckedAt: localTimeToUtcISOString(moment().subtract(3, 'days').toDate())!,
      },
      setActionData: (data) => {
        set((state) => {
          return {
            ...state,
            actionData: data,
            step: state.step + 1,
          };
        });
      },
      getSessionId: () => {
        const currentDate = new Date();
        const updatedDate = get().updatedAt;
        let newSessionId = get().sessionId;
        if (moment(updatedDate).add(30, 'minutes') < moment(currentDate)) {
          newSessionId = getUniqueKeyFromUUID();
          set({ sessionId: newSessionId, updatedAt: currentDate, step: 0 });
        }
        return newSessionId;
      },
      setLastCheckDates: (data) => {
        set((state) => {
          const prev = get().lastCheckDates;
          return { ...state, lastCheckDates: { ...prev, ...data } };
        });
      },
    }),
    {
      name: 'igogo-app-session',
    },
  ),
);
