import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { ReactComponent as BellIcon } from '@assets/images/bell.svg';
import { ReactComponent as TextLogo } from '@assets/images/igogo-text-logo.svg';
import { useAuthentication } from '@hooks/authentication';
import { useViewport } from '@hooks/viewport';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { cdnImageUrl } from '@utils/image';
import { KAKAO_TUTOR_CENTER_URL, WEB_URL } from '@variables';

export const NavBar: React.FC<{ onChangeShowSider?: (value: boolean) => void; hide?: boolean }> = (props) => {
  const [showSider, setShowSider] = useState<boolean>(false);
  const { isMobile } = useViewport();
  const dialog = useOkCancelDialog();
  const { logout, authStore } = useAuthentication();

  useEffect(() => {
    props.onChangeShowSider && props.onChangeShowSider(showSider);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSider]);

  if (props.hide) {
    return <div></div>;
  }

  const handleInquiryClick = () => {
    dialog.open({
      type: 'ok_cancel',
      title: '파트너센터 문의',
      content:
        '[운영시간 : 평일 09:30 ~ 17:00]\n평일 오후 17시 이후의 문의는\n다음 영업일에 순차적으로 답변이 진행됩니다.',
      onConfirm: () => window.open(KAKAO_TUTOR_CENTER_URL, '_blank'),
      confirmText: '문의하기',
    });
  };

  return (
    <div className="nav-bar">
      {isMobile && (
        <button
          className="link-button"
          style={{ margin: '12px' }}
          onClick={() => {
            setShowSider((value) => !value);
          }}>
          {showSider ? (
            <MenuUnfoldOutlined style={{ fontSize: '20px' }} />
          ) : (
            <MenuFoldOutlined style={{ fontSize: '20px' }} />
          )}
        </button>
      )}
      <div
        style={{
          width: '280px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: isMobile ? 'flex-start' : 'center',
          height: '80px',
        }}>
        <Link to={'/dashboard'}>
          <TextLogo />
        </Link>
        <div
          style={{
            width: '1px',
            height: '16px',
            backgroundColor: 'var(--Neutral-50, #B9BBBE)',
            margin: '0 16px',
          }}></div>
        <div
          style={{
            color: 'var(--Neutral-90, #424242)',
            fontFamily: 'Pretendard',
            fontSize: isMobile ? '16px' : '20px',
            fontStyle: 'normal',
            fontWeight: 700,
          }}>
          파트너센터
        </div>
      </div>
      <div style={{ display: 'flex', gap: isMobile ? '8px' : '28px', marginRight: '40px' }}>
        {!isMobile && (
          <button
            className="link-button"
            style={{
              color: 'var(--Neutral-90, #424242)',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 700,
            }}
            onClick={() => {
              window.open(WEB_URL, '_blank');
            }}>
            아이고고 홈
          </button>
        )}
        {!isMobile && (
          <button
            className="link-button"
            onClick={() => handleInquiryClick()}
            style={{
              color: 'var(--Neutral-90, #424242)',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 700,
            }}>
            이용 문의
          </button>
        )}
        <button className="link-button" style={{ display: 'none' }}>
          <BellIcon />
        </button>
        <button
          className="link-button"
          style={{
            color: 'var(--Neutral-90, #424242)',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 700,
          }}
          onClick={() => {
            dialog.open({
              title: '로그아웃',
              content: '로그아웃 할까요?',
              type: 'ok_cancel',
              onConfirm: () => {
                logout();
              },
            });
          }}>
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            {!isMobile ? (
              authStore.user?.profile?.imageUrl ? (
                <img
                  style={{ width: '32px', height: '32px', borderRadius: '50%', objectFit: 'cover' }}
                  src={cdnImageUrl(authStore.user?.profile?.imageUrl)}
                  alt=""
                />
              ) : (
                <Avatar size={32} icon={<UserOutlined />} />
              )
            ) : (
              <></>
            )}
            <div style={{ flexShrink: 0 }}>로그아웃</div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default NavBar;
