import React from 'react';
import { ReactComponent as CloseIcon } from '@assets/images/x.svg';
import ProductDetailView from '@components/products/ProductDetailView';
import useProductPreViewModalStore from '@stores/productPreViewModalStore';

export const ProductPreViewModal: React.FC = () => {
  const { show, data, close } = useProductPreViewModalStore();
  if (!show) {
    return <></>;
  }

  return (
    <div key={`product-preview-modal-${data?._id}`} className="partner-modal">
      <div className="partner-modal__container">
        <div className="partner-modal__header">
          <div className="partner-modal__title">{data?.title}</div>
          <CloseIcon style={{ cursor: 'pointer' }} onClick={() => close()} />
        </div>
        <div style={{ width: '480px' }}>
          <ProductDetailView product={data!}></ProductDetailView>
        </div>
      </div>
    </div>
  );
};
