import { Address } from 'react-daum-postcode';
import { RcFile } from 'antd/es/upload';
import HomeIcon from '@assets/images/productdetailview/icon-detail-home.png';
import OfficeIcon from '@assets/images/productdetailview/icon-detail-office.png';
import OnlineIcon from '@assets/images/productdetailview/icon-detail-online.png';
import { Membership } from './membership';

export interface AddressInfo extends Address {
  address2?: string;
}

export interface ClassBadge {
  type: string;
  isActive: boolean;
  value: string;
}

export interface AvailableDistrict {
  state: string;
  city: string;
}

export interface SpecificAddress {
  postcode: string;
  postcode1: string;
  postcode2: string;
  postcodeSeq: string;
  zonecode: string;
  address: string;
  addressEnglish: string;
  addressType: string;
  bcode: string;
  bname: string;
  bnameEnglish: string;
  bname1: string;
  bname1English: string;
  bname2: string;
  bname2English: string;
  sido: string;
  sidoEnglish: string;
  sigungu: string;
  sigunguEnglish: string;
  sigunguCode: string;
  userLanguageType: string;
  query: string;
  buildingName: string;
  buildingCode: string;
  apartment: string;
  jibunAddress: string;
  jibunAddressEnglish: string;
  roadAddress: string;
  roadAddressEnglish: string;
  autoRoadAddress: string;
  autoRoadAddressEnglish: string;
  autoJibunAddress: string;
  autoJibunAddressEnglish: string;
  userSelectedType: string;
  noSelected: string;
  hname: string;
  roadnameCode: string;
  roadname: string;
  roadnameEnglish: string;
  address2: string;
}

export interface ProductLocationData {
  specificAddress?: SpecificAddress | AddressInfo;
  availableDistricts?: AvailableDistrict[];
}

export interface BottomTextBanner {
  show?: boolean;
  iconTemp?: string;
  icon?: string;
  highlightText?: string;
  normalText?: string;
}

export interface Concept {
  conceptTitle?: string;
  conceptData?: string;
  images?: string[];
  imagesTemp?: string[];
}

export interface CommerceFixedOptionItem {
  title?: string;
  fee?: number;
  essential?: boolean;
  inventory?: number;
}

export interface ProductOption {
  _id?: string;
  productId?: string;
  step: string[];
  type?: 'direct' | 'sequential';
  fee?: number;
  inventory?: number;
  index?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum ProductDetailType {
  TEMPLATE = 'template',
  IMAGES = 'images',
}
export interface ProductDetails {
  introductions?: { title?: string; description?: string }[];
  concepts?: Concept[];
  preparationText?: string;
  recommendText?: string;
}

export interface ProductDetailsEditForm {
  introductions?: { title?: string; description?: string }[];
  concepts?: Concept[];
  recommends?: string[];
  preparations?: string[];
}

export interface DiscountInfo {
  discountStartDate?: Date;
  discountEndDate?: Date;
  discountedFee?: number;
}

export enum ProductActivityType {
  HOME = 'home',
  STUDIO = 'studio',
  EXPERIENTIAL = 'experiential',
  ONLINE = 'online',
  DIGITAL = 'digital',
  TICKET = 'ticket',
  DELIVERY = 'delivery',
}

export enum ProductSaleType {
  CLASS = 'class',
  COMMERCE = 'commerce',
}

export class CustomerServiceInfo {
  mainCustomerService?: 'url' | 'phone';
  url?: string;
  phone?: string;
}

export enum LegacyProductStatus {
  ALL = 'all',
  OPEN = 'open',
  CLOSE = 'close',
  HIDE = 'hide',
  DENIED = 'denied',
  OPEN_REQUEST = 'open_request',
  EDIT = 'edit',
  PRE_OPEN = 'pre_open',
}
export enum ProductStatus {
  OPEN = 'open',
  CLOSE = 'close',
  PRIVATE = 'private',
}
export enum ProductApproveStatus {
  ACCEPTED = 'accepted',
  REQUESTED = 'requested',
  DENIED = 'denied',
  NOT_READY = 'not_ready',
}

export interface ProductEditModel extends Product {
  classIntroductionImageTemp?: string;
  thumbnailImageTemp?: string;
  detailImagesTemp?: string[];
  detailBodyImagesTemp?: string[];
}

export interface QuestionOption {
  question: string;
}

export interface Product {
  _id: string;
  sellerName: string;
  sellerId: string;
  sellerProfileImage?: string;
  sellerIntroduction?: string;
  title: string;
  saleType: ProductSaleType;
  activityType?: string;
  thumbnailImage: string;
  closeText?: string;
  discount?: string;
  originFee: number;
  fee: number;
  classDuration?: number;
  totalNumber: number;
  shippingProduct?: boolean;
  showRegularTime?: boolean;
  regularTime?: number;
  tags: string[];
  badges: ClassBadge[];
  location: ProductLocationData;
  detailType: ProductDetailType;
  detailImages: string[];
  detailImagesTemp?: string[];
  detailHtml: string;
  details: ProductDetails;
  detailBodyImages?: string[];
  classIntroductionImage?: string;
  refundPolicyText?: string;
  shippingPolicyText?: string;
  classTermsText?: string;
  refundPolicies?: string[];
  applyGroupDiscount?: boolean;
  ageMin: number;
  ageMax: number;
  studentsMin?: number;
  studentsMax?: number;
  categories: string[];
  bottomTextBanner?: BottomTextBanner;
  display?: boolean;
  soldout?: boolean;
  isClosed?: boolean;
  totalInventory?: number;
  shippingFee?: number;
  memoRequired?: boolean;
  memoComment?: string;
  sellerComment?: string;
  sellerShortComment?: string;
  status?: ProductStatus | LegacyProductStatus;
  approveStatus?: ProductApproveStatus;
  optionType?: string; // direct / sequential / sequential_with_direct
  purchaseQuantityOptions?: CommerceFixedOptionItem[];
  productOptions?: ProductOption[];
  optionLabels?: string[];
  meta: any;
  priority?: number;
  approvedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  applyPeriodDiscount?: boolean;
  discountInfo?: DiscountInfo;
  shippingFeeExtra?: number;
  shippingComment?: string;
  customerServiceInfo?: CustomerServiceInfo;
  questionOptions?: QuestionOption[];
  deniedReason?: string;
  favoritedCount?: number;
  memberships?: Membership[];
}

export enum StockStatus {
  SOLDOUT = 'soldout',
  IN_STUCK = 'in_stock',
  SOLDOUT_STATUS = 'soldout_status',
  NO_SOLDOUT_STATUS = 'no_soldout_status',
}

export interface ProductSearchQuery {
  keyword?: string;
  saleType?: string;
  sort?: string;
  sortType?: string; // createdAt_desc | updatedAt_desc
  activityType?: string;
  category?: string;
  age?: string;
  region?: string;
  status?: ProductStatus | '';
  approveStatus?: ProductApproveStatus | '';
  displayStatus?: string;
  stockStatus?: StockStatus;
  page?: number;
  size?: number;
}

export interface ProductDefaultInfo {
  activityType?: string;
  selectedCategories?: string[];
  detailImageFiles?: { [key: string]: RcFile };
  detailImagesTemp?: string[];
  detailImages?: string[];
  thumbnailImageFile?: { [key: string]: RcFile };
  thumbnailImageTemp?: string;
  thumbnailImage?: string;
  productName?: string;
  ageMin?: number;
  ageMax?: number;
  duration?: { hour?: number; minute?: number; duration?: number };
  studentsMin?: number;
  studentsMax?: number;
  location?: ProductLocationData;
}

export interface ProductDetailInfo {
  tags?: string[];
  classIntroductionImageFile?: { [key: string]: RcFile };
  classIntroductionImageTemp?: string[];
  classIntroductionImage?: string[];
  conceptImageFiles?: { [key: string]: RcFile };
  detailBodyImageFiles?: { [key: string]: RcFile };
  detailBodyImagesTemp?: string[];
  detailBodyImages?: string[];
  details?: ProductDetailsEditForm;
  detailType?: ProductDetailType;
  sellerComment?: string;
}

export interface ProductPriceInfo {
  fee?: number;
  originFee?: number;
  shippingProduct?: boolean;
  shippingFee?: number;
  shippingFeeExtra?: number;
  shippingComment?: string;
  shippingPolicyText?: string;
  refundPolicyText?: string;
  applyGroupDiscount?: boolean;
  applyPeriodDiscount?: boolean;
  discountInfo?: DiscountInfo;
}

export const PRODUCT_ACTIVITY_TYPE_TEXT: { [key: string]: { title: string; description: string; icon: string } } = {
  studio: { title: '스튜디오', description: '튜터님이 운영하는 공간으로 방문하는 수업입니다.', icon: OfficeIcon },
  experiential: { title: '체험수업', description: '특정 학습장소에서 진행하는 수업입니다.', icon: OfficeIcon },
  home: { title: '가정방문', description: '튜터님이 집으로 오시는 방문수업입니다.', icon: HomeIcon },
  online: {
    title: '비대면',
    description: '튜터님과 협의한 시간에 실시간 비대면으로 진행되는 수업입니다.',
    icon: OnlineIcon,
  },
  digital: { title: '디지털상품', description: 'PDF 등의 디지털 파일을 구매하는 상품입니다.', icon: OnlineIcon },
  ticket: { title: '티켓상품', description: '티켓 상품입니다.', icon: OnlineIcon },
  shipping: { title: '배송상품', description: '기입하신 주소지로 배송되는 상품입니다.', icon: OnlineIcon },
};
