import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Layout } from 'antd';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';
import * as commonAPI from '@apis/common';
import * as paymentAPI from '@apis/paymentStatements';
import { Roles } from '@consts/role';
import { useAuthentication } from '@hooks/authentication';
import { useViewport } from '@hooks/viewport';
import { useAppUserSessionStore } from '@stores/appUserSessionStore';
import { cdnImageUrl } from '@utils/image';

interface SiderProps {
  hide?: boolean;
}

interface MenuButtonProps {
  className?: string;
  selected?: boolean;
  label: string;
  badgeCount?: number;
  onClick: (event: any) => void;
}
const MenuButton: React.FC<MenuButtonProps> = (props) => {
  return (
    <button
      className={`link-button ${props.className}`}
      style={{
        display: 'flex',
        height: '52px',
        alignItems: 'center',
        color: props.selected ? 'var(--White-100, #FFF)' : 'var(--White-70, rgba(255, 255, 255, 0.70))',
        fontFamily: 'Pretendard',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
      }}
      onClick={props.onClick}>
      {props.label}
      {props?.badgeCount !== undefined && props?.badgeCount > 0 && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '14px',
            height: '14px',
            marginLeft: '6px',
            borderRadius: '20px',
            backgroundColor: '#FF3D8F',
            fontSize: '11px',
          }}>
          {props.badgeCount}
        </div>
      )}
    </button>
  );
};

const Sider: React.FC<SiderProps> = (props) => {
  const { isMobile } = useViewport();
  const { authStore } = useAuthentication();
  const navigate = useNavigate();
  const sessionStore = useAppUserSessionStore();
  const platformPaymentLastDate = localStorage.getItem('platform-payment-statements-last-date') || '0';
  const platformCheckLastDate = localStorage.getItem('platform-payment-statements-check-date') || '0';
  const naverPaymentLastDate = localStorage.getItem('naver-payment-statements-last-date') || '0';
  const naverCheckLastDate = localStorage.getItem('naver-payment-statements-check-date') || '0';

  const newUpdatedCountsQuery = useQuery({
    queryKey: [`new-updated-counts-${authStore?.user?.id}`, sessionStore.lastCheckDates],
    queryFn: () => commonAPI.getNewUpdatedCounts(sessionStore.lastCheckDates),
  });
  const updatedPaymentStatementsCount = newUpdatedCountsQuery?.data?.data?.paymentStatements || 0;
  const updatedSalesChannelOrdersCount = newUpdatedCountsQuery?.data?.data?.saleChannelOrders || 0;

  const { data } = useQuery({
    queryKey: [`payment-statements-lastDate-${authStore.user?.id}`],
    queryFn: paymentAPI.getPaymentStatementLastDate,
    staleTime: 1 * 60 * 1000,
    enabled: false,
  });

  useEffect(() => {
    if (data?.data) {
      const lastDateData = data.data;
      if (lastDateData.platform) {
        localStorage.setItem('platform-payment-statements-last-date', lastDateData.platform.toString());
      }
      if (lastDateData.naver) {
        localStorage.setItem('naver-payment-statements-last-date', lastDateData.naver.toString());
      }
    }
  }, [data?.data]);

  const isNewBadgeMenu = (link: string) => {
    if (link === '/platform-orders' && Number(platformPaymentLastDate) > Number(platformCheckLastDate)) {
      return true;
    }
    if (link === '/channel-orders' && Number(naverPaymentLastDate) > Number(naverCheckLastDate)) {
      return true;
    }
    return false;
  };

  if (props.hide || window.location.pathname === '/notFound') {
    return <div id="hidden-sider"></div>;
  }

  return (
    <Layout.Sider
      width={280}
      style={{
        backgroundColor: '#242424',
        position: isMobile ? 'fixed' : 'static',
        zIndex: 997,
        top: '81px',
        height: 'calc(100dvh - 81px)',
      }}>
      <OverlayScrollbarsComponent
        defer
        options={{
          scrollbars: { autoHide: 'scroll' },
          overflow: {
            x: 'hidden',
          },
        }}
        style={{
          marginTop: 0,
          position: 'relative',
          maxHeight: 'calc(100vh - 80px)',
          overflow: 'auto',
          width: '100%',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
            height: '136px',
            gap: '12px',
            borderBottom: '1px solid var(--White-10, rgba(255, 255, 255, 0.10))',
          }}>
          <div>
            {authStore.user?.profile?.imageUrl ? (
              <img
                style={{ width: '60px', height: '60px', borderRadius: '50%', objectFit: 'cover' }}
                src={cdnImageUrl(authStore.user?.profile?.imageUrl)}
                alt=""
              />
            ) : (
              <Avatar size={60} icon={<UserOutlined />} />
            )}
          </div>
          <div
            style={{ color: '#FFF', fontFamily: 'Pretendard', fontSize: '18px', fontStyle: 'normal', fontWeight: 700 }}>
            {authStore.user?.profile?.name}
          </div>
        </div>
        <div
          style={{
            borderBottom: '1px solid var(--White-10, rgba(255, 255, 255, 0.10))',
            padding: '12px 20px 12px 40px',
          }}>
          {[
            { label: '대시보드', link: '/dashboard' },
            { label: '파트너 프로필', link: '/profile' },
            { label: '계정정보', link: '/account' },
          ].map((item) => {
            return (
              <MenuButton
                label={item.label}
                selected={item.link === window.location.pathname}
                onClick={() => {
                  navigate(item.link);
                }}
              />
            );
          })}
        </div>
        <div
          style={{
            borderBottom: '1px solid var(--White-10, rgba(255, 255, 255, 0.10))',
            padding: '12px 20px 12px 40px',
          }}>
          {[
            { label: '상품 관리', link: '/products' },
            { label: '플랫폼 판매 관리', link: '/platform-orders', badgeCount: updatedPaymentStatementsCount },
            { label: '외부채널 판매 관리', link: '/channel-orders', badgeCount: updatedSalesChannelOrdersCount },
            { label: '정산내역 관리', link: '/settlements' },
            { label: '수강증 관리', link: '/invoices' },
            { label: '후기 관리', link: '/reviews' },
            { label: '콘텐츠 관리', link: '/user-contents' },
            { label: '멤비십 회원 관리', link: '/membership-users' },
            { label: '멤버십 상품 조회', link: '/membership-products' },
          ]
            .filter((item) => {
              if (
                authStore.user?.roles?.includes(Roles.PARTNER) ||
                authStore.user?.roles?.includes(Roles.SELLER) ||
                authStore.user?.roles?.includes(Roles.TUTOR)
              ) {
                if (item.link === '/channel-orders') {
                  if (!authStore.user?.roles?.includes(Roles.TUTOR)) {
                    return true;
                  }
                  return false;
                } else if (item.link === '/invoices') {
                  if (authStore.user?.roles?.includes(Roles.TUTOR)) {
                    return true;
                  } else {
                    return false;
                  }
                } else if (['/products', '/platform-orders', '/settlements', '/reviews'].includes(item.link)) {
                  return true;
                }
              }

              if (item.link === '/user-contents') {
                if (authStore.user?.roles?.includes(Roles.EDITOR)) {
                  return true;
                } else {
                  return false;
                }
              } else if (item.link === '/membership-users' || item.link === '/membership-products') {
                if (authStore.user?.roles?.includes(Roles.MEMBERSHIP_PARTNER)) {
                  return true;
                } else {
                  return false;
                }
              }
              return false;
            })
            .map((item, index) => {
              return (
                <MenuButton
                  key={`sider-menu-${index}`}
                  className={isNewBadgeMenu(item.link) ? 'new-badge' : ''}
                  label={item.label}
                  badgeCount={item?.badgeCount}
                  selected={item.link === window.location.pathname}
                  onClick={() => {
                    navigate(item.link);
                  }}
                />
              );
            })}
        </div>
        <div style={{ padding: '12px 20px 12px 40px' }}>
          {[{ label: '공지사항', link: '/notice' }].map((item) => {
            return (
              <MenuButton
                label={item.label}
                selected={item.link === window.location.pathname}
                onClick={() => {
                  navigate(item.link);
                }}
              />
            );
          })}
        </div>
      </OverlayScrollbarsComponent>
    </Layout.Sider>
  );
};

export default Sider;
