import React from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import TopBackground from '@assets/images/productdetailview/img-classDetail-bg.png';
import GuideIcon1 from '@assets/images/productdetailview/img-guide-1.png';
import GuideIcon2 from '@assets/images/productdetailview/img-guide-2.png';
import GuideIcon3 from '@assets/images/productdetailview/img-guide-3.png';
import GuideIcon4 from '@assets/images/productdetailview/img-guide-4.png';
import GuideIcon5 from '@assets/images/productdetailview/img-guide-5.png';
import AppPreviewModal from '@components/modal/AppPreviewModal';

export const AppServiceCautionsModal: React.FC<{ open?: boolean; onCancel?: () => void }> = (props) => {
  return (
    <AppPreviewModal
      height={'calc(100% - 60px)'}
      title={'신청 전 유의사항'}
      open={props.open}
      hideRightNaviButton
      onLeftNaviButtonClick={() => {
        props.onCancel && props.onCancel();
      }}
      onRightNaviButtonClick={() => {
        props.onCancel && props.onCancel();
      }}>
      <OverlayScrollbarsComponent
        defer
        options={{
          scrollbars: { autoHide: 'scroll' },
          overflow: {
            x: 'hidden',
          },
        }}
        style={{
          backgroundColor: '#ffffff',
          marginTop: 0,
          position: 'relative',
          overflow: 'auto',
          height: '100%',
          width: '100%',
        }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'absolute',
              top: '47px',
              zIndex: 1000,
            }}>
            <div style={{ fontSize: '26px', fontWeight: 'bold', color: '#242424' }}>클래스 신청 전 필독!</div>
            <div style={{ fontSize: '16px', fontWeight: '500', color: '#808387', marginTop: '12px' }}>
              아이고고 이용시 꼭 지켜주세요
            </div>
          </div>
          <img style={{ width: '100%', aspectRatio: 375 / 190, objectFit: 'cover' }} src={TopBackground} alt="" />
        </div>
        <div style={{ padding: '20px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              paddingBottom: '20px',
              paddingTop: '20px',
              borderBottomWidth: 1,
              borderBottomColor: '#eff1f4',
            }}>
            <img style={{ width: '60px', height: '60px', marginRight: '16px' }} src={GuideIcon1} alt="" />
            <div style={{ width: '100%' }}>
              <div style={{ fontSize: '17px', fontWeight: 'bold', color: '#242424' }}>
                개인 거래 시도에 유의해주세요.
              </div>
              <div style={{ fontSize: '14px', color: '#66686b', marginTop: '6px' }}>
                아이고고 수강증 기능을 이용하여 결제해주세요. 개인 거래를 시도할 경우, 이용약관에 의해 활동정지 조치가
                될 수 있어요.
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              paddingBottom: '20px',
              paddingTop: '20px',
              borderBottomWidth: 1,
              borderBottomColor: '#eff1f4',
            }}>
            <img style={{ width: '60px', height: '60px', marginRight: '16px' }} src={GuideIcon2} alt="" />
            <div style={{ width: '100%' }}>
              <div style={{ fontSize: '17px', fontWeight: 'bold', color: '#242424' }}>
                개인정보 공유는 패널티 대상이 될 수 있어요.
              </div>
              <div style={{ fontSize: '14px', color: '#66686b', marginTop: '6px' }}>
                부정거래 방지 및 사생활 보호를 위해 계좌번호, 카톡ID 등의 개인정보는 공유하실 수 없어요.
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              paddingBottom: '20px',
              paddingTop: '20px',
              borderBottomWidth: 1,
              borderBottomColor: '#eff1f4',
            }}>
            <img style={{ width: '60px', height: '60px', marginRight: '16px' }} src={GuideIcon3} alt="" />
            <div style={{ width: '100%' }}>
              <div style={{ fontSize: '17px', fontWeight: 'bold', color: '#242424' }}>
                환불원칙은 클래스마다 달라요.
              </div>
              <div style={{ fontSize: '14px', color: '#66686b', marginTop: '6px' }}>
                환불정책은 클래스 종류 및 예약 시기에 따라 상이할 수 있습니다. 튜터님과 상담 시 환불원칙을 꼭
                확인해주세요.
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              paddingBottom: '20px',
              paddingTop: '20px',
              borderBottomWidth: 1,
              borderBottomColor: '#eff1f4',
            }}>
            <img style={{ width: '60px', height: '60px', marginRight: '16px' }} src={GuideIcon4} alt="" />
            <div style={{ width: '100%' }}>
              <div style={{ fontSize: '17px', fontWeight: 'bold', color: '#242424' }}>
                스케줄 변경은 반드시 튜터님과 상의해주세요.
              </div>
              <div style={{ fontSize: '14px', color: '#66686b', marginTop: '6px' }}>
                불가피하게 수업 스케줄 변경, 연기하셔야 할 때는 사전에 튜터님과 상의해주세요.
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              paddingTop: '20px',
            }}>
            <img style={{ width: '60px', height: '60px', marginRight: '16px' }} src={GuideIcon5} alt="" />
            <div style={{ width: '100%' }}>
              <div style={{ fontSize: '17px', fontWeight: 'bold', color: '#242424' }}>
                수업 전 건강상태 체크 및 방역수칙을 준수해주세요.
              </div>
              <div style={{ fontSize: '14px', color: '#66686b', marginTop: '6px' }}>
                코로나19 관련 방역수칙을 준수해주시고, 아이와 부모님의 건강상태를 수업 전에 체크해주세요.
              </div>
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </AppPreviewModal>
  );
};

export default AppServiceCautionsModal;
