import { Fragment, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Checkbox, Popover, Table } from 'antd';
import { CheckboxGroupProps, CheckboxProps } from 'antd/es/checkbox';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import * as api from '@apis/paymentStatements';
import ExcelButton from '@components/common/ExcelButton';
import MonthPicker from '@components/common/MonthPicker';
import { Roles } from '@consts/role';
import { useAuthentication } from '@hooks/authentication';
import { GetPaymentStatementsQuery, PaymentStatementPurchase, SaleType } from '@models/paymentStatement';
import { ProductOrderViewModel } from '@models/productOrderView';
import { SalesChannelsType } from '@models/sales-channels';

const SettlementTableFooter: React.FC<{ settlementAmount: number; pendingSettlementAmount: number }> = (props) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Pretendard',
        padding: '20px',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          lineHeight: '24px',
          gap: '12px',
        }}>
        <div style={{ fontSize: '14px', fontWeight: 400, color: '#424242' }}>정산완료금액 합계</div>
        <div style={{ fontSize: '18px', fontWeight: 700, color: '#FF3D8F', marginRight: '60px' }}>
          {`${props.settlementAmount.toLocaleString()}`}원
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          lineHeight: '24px',
          gap: '12px',
        }}>
        <div style={{ fontSize: '14px', fontWeight: 400, color: '#424242' }}>정산예정금액 합계</div>
        <div style={{ fontSize: '18px', fontWeight: 700, color: '#FF3D8F' }}>
          {`${props.pendingSettlementAmount.toLocaleString()}`}원
        </div>
      </div>
    </div>
  );
};

const SettlementsPage: React.FC = () => {
  const { authStore } = useAuthentication({ loginRequired: true });
  const [checkedSaleType, setCheckedSaleType] = useState<SaleType[]>(['class', 'commerce']);
  const [searchPeriod, setSearchPeriod] = useState<{ startDate: Date; endDate: Date }>({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth()),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1),
  });
  const [query, setQuery] = useState<GetPaymentStatementsQuery>({
    startDate: searchPeriod.startDate,
    endDate: searchPeriod.endDate,
    saleType: '',
    page: 0,
    size: 10,
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: false,
  });
  const layoutContentScrollRef = document.getElementById('layout-content');

  const settlementTargetsQuery = useQuery({
    queryKey: [`settlements-${authStore?.user?.id}`, query],
    queryFn: () => api.getSettlements(query),
  });
  const currentMonthSettlementSummaryQuery = useQuery({
    queryKey: [`settlements-summary-${authStore?.user?.id}`],
    queryFn: () => api.getCurrentMonthSettlementSummary(),
  });
  const currentMonthSettlementSummary = currentMonthSettlementSummaryQuery?.data?.data;
  const currentMonth = moment().month() + 1;

  const TABLE_COLUMNS: ColumnsType<ProductOrderViewModel> = [
    {
      title: '채널',
      align: 'center',
      render: (record) => {
        if (record?.channel) {
          if ([SalesChannelsType.NAVER_STORE, SalesChannelsType.INBOUND_NAVER].includes(record.channel)) {
            return '네이버';
          } else if ([SalesChannelsType.KAKAO_RESERVATION].includes(record.channel)) {
            return '카카오';
          } else {
            return '아이고고';
          }
        }
        return '아이고고';
      },
    },
    {
      title: '판매유형',
      align: 'center',
      render: (record) => {
        const saleType = record?.product?.saleType || 'commerce';
        if (saleType === 'commerce') {
          return '커머스';
        }
        return '클래스';
      },
    },
    {
      title: '결제일시',
      dataIndex: 'payment',
      render: (payment) => {
        if (!payment?.paidAt) {
          return '-';
        }
        return moment(payment?.paidAt).format('YYYY.MM.DD HH:mm');
      },
      align: 'center',
    },
    {
      title: '구매자',
      dataIndex: 'buyer',
      render: (buyer) => `${buyer.name}${authStore.user?.role !== Roles.TUTOR ? `(${buyer.phone})` : ''}`,
      align: 'center',
    },
    {
      title: '상품명',
      dataIndex: 'product',
      render: (product) => (
        <Popover content={product.name}>
          <div style={{ width: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {product.name}
          </div>
        </Popover>
      ),
      align: 'center',
    },
    {
      title: '구매옵션',
      render: (record) => {
        const purchases = record?.purchase ? [record.purchase] : [...(record?.purchases || [])];
        return (
          <Popover
            content={purchases.map((item: PaymentStatementPurchase) => (
              <div>{`${item?.name}(${item?.count})`}</div>
            ))}>
            <div>{purchases.map((item: PaymentStatementPurchase) => `${item?.name}(${item?.count})`).join('\n')}</div>
          </Popover>
        );
      },
      align: 'center',
      hidden: authStore.user?.role === Roles.TUTOR,
    },
    {
      title: '수업일시',
      render: (record) => {
        const plannedDates = record?.plannedDates
          ? [...(record?.plannedDates || [])]
          : record?.plannedDate
            ? [record?.plannedDate]
            : [];
        if (!plannedDates || !plannedDates.length) return '-';
        return (
          <pre>
            {plannedDates?.map((plannedDate: Date) => moment(plannedDate).format('YYYY.MM.DD HH:mm')).join('\n')}
          </pre>
        );
      },
      align: 'center',
    },
    {
      title: <p style={{ textAlign: 'center' }}>결제금액</p>,
      dataIndex: 'payment',
      render: (payment) => `${payment.amount.toLocaleString()}원`,
      align: 'right',
    },
    {
      title: <p style={{ textAlign: 'center' }}>환불금액</p>,
      dataIndex: 'refund',
      render: (refund) => `${(refund?.amount || 0).toLocaleString()}원`,
      align: 'right',
    },
    {
      title: <p style={{ textAlign: 'center' }}>정산금액</p>,
      render: (record) => `${(record?.settlementAmount || 0).toLocaleString()}원`,
      align: 'right',
    },
    {
      title: '정산상태',
      render: (record) => {
        if (['done', 'DONE'].includes(record.statuses?.settlement) && record?.settlement?.transferAt) {
          return `정산완료`;
        }
        return '정산대기';
      },
      align: 'center',
    },
    {
      title: '정산일시',
      dataIndex: 'settlement',
      render: (settlement) => {
        if (!settlement?.transferAt) {
          return '-';
        }
        return moment(settlement?.transferAt).format('YYYY.MM.DD HH:mm');
      },
      align: 'center',
    },
  ];
  const CLASS_TYPE_LABEL = [
    { label: '클래스', value: 'class' as const },
    { label: '커머스', value: 'commerce' as const },
  ];

  useEffect(() => {
    if (settlementTargetsQuery.data?.data?.page !== undefined) {
      setPagination((value) => {
        const newValue = { ...value };
        newValue.current = (settlementTargetsQuery.data?.data.page || 0) + 1;
        newValue.pageSize = settlementTargetsQuery.data?.data.size || 10;
        newValue.total = settlementTargetsQuery.data?.data.total || 0;
        return newValue;
      });
    }
  }, [settlementTargetsQuery.data?.data]);

  const handleAllCheckedSaleType: CheckboxProps['onChange'] = (e) => {
    const saleTypeList: SaleType[] = ['class', 'commerce'];
    setCheckedSaleType(e.target.checked ? saleTypeList : []);
  };
  const handleSaleTypeChange: CheckboxGroupProps<SaleType>['onChange'] = (e) => {
    setCheckedSaleType(e);
  };

  const scrollToBottom = () => {
    if (layoutContentScrollRef) {
      layoutContentScrollRef.scrollTo({ top: Number.MAX_SAFE_INTEGER, behavior: 'smooth' });
    }
  };

  return (
    <section>
      <h2 className="page-title">정산 내역</h2>
      <section className="page-menu">
        <div style={{ padding: '16px 0' }}>
          <div
            style={{
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '22px',
              color: '#424242',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <div style={{ width: '200px' }}>{currentMonth}월 정산예정금액</div>
            <div style={{ width: '200px' }}>{currentMonth}월 정산완료금액</div>
          </div>
          <div
            style={{
              fontFamily: 'Pretendard',
              fontSize: '28px',
              fontWeight: 700,
              color: '#242424',
              marginTop: '12px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <div style={{ width: '200px' }}>
              {`${(currentMonthSettlementSummary?.pendingSettlementAmount || 0).toLocaleString()}`}
              <span style={{ fontSize: '16px', color: '#808387' }}>원</span>
            </div>
            <div style={{ width: '200px' }}>
              {`${(currentMonthSettlementSummary?.settlementAmount || 0).toLocaleString()}`}
              <span style={{ fontSize: '16px', color: '#808387' }}>원</span>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: '12px',
            padding: '20px',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 400,
            color: '#66686b',
            whiteSpace: 'pre-wrap',
            backgroundColor: '#F8F9FC',
          }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '16px' }}>
            <div style={{ width: '235px', gap: '8px' }}>
              <div style={{ marginBottom: '8px', fontWeight: 700, color: '#424242' }}>클래스 상품 정산주기</div>
              <div>주정산</div>
            </div>
            <div style={{ width: '235px' }}>
              <div style={{ marginBottom: '8px', fontWeight: 700, color: '#424242' }}>커머스 상품 정산주기</div>
              <div>월정산</div>
            </div>
          </div>
          <hr className="page-menu__divider" />
          <div>
            • 주정산: 수업완료 후 리포트 발송일 기준으로 매주 월요일부터 일요일까지 집계된 금액을 영업일 기준 차주
            수요일에 지급.
            {'\n'}• 월정산: 구매확정일(수업/체험일정일 +3일) 기준으로 매월 1일부터 말일까지 집계된 금액을 영업일 기준
            익월 10일에 지급.
            <div style={{ marginTop: '10px', marginLeft: '52px', color: '#0180ef', fontSize: '12px' }}>
              <div>
                *구매확정일: 수업 및 체험이 이루어진 뒤 3일, 고객으로부터 환불/교환등의 요청이 없음을 확정하고 고객에게
                적립금을 지급하는 일자
              </div>
              <div>*지급일이 주말, 공휴일일 경우 익일 영업일에 지급</div>
            </div>
          </div>
        </div>
      </section>
      <hr className="page-divider" />
      <section className="page-menu">
        <Fragment>
          <section className="page-menu__item">
            <p className="page-menu__label">판매유형</p>
            <Checkbox
              className="black-checkbox"
              checked={checkedSaleType.length === 2}
              onChange={handleAllCheckedSaleType}>
              전체
            </Checkbox>
            <Checkbox.Group
              className="black-checkbox"
              options={CLASS_TYPE_LABEL}
              value={checkedSaleType}
              onChange={handleSaleTypeChange}
            />
          </section>
          <hr className="page-menu__divider" />
        </Fragment>
        <section className="page-menu__item">
          <p className="page-menu__label">기간</p>
          <MonthPicker
            value={query?.startDate as Date}
            onChange={(value) => {
              const startDate = new Date(value.getFullYear(), value.getMonth());
              const endDate = new Date(value.getFullYear(), value.getMonth() + 1);
              setSearchPeriod({ startDate, endDate });
            }}
          />
        </section>
        <hr className="page-menu__divider" />
        <section className="page-menu__item">
          <p className="page-menu__label"></p>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
            <button
              className="pa-button black wxl"
              onClick={() => {
                scrollToBottom();
                const saleType = checkedSaleType.length === 1 ? checkedSaleType[0] : '';
                setQuery((prev) => {
                  return {
                    ...prev,
                    saleType,
                    startDate: searchPeriod.startDate,
                    endDate: searchPeriod.endDate,
                  };
                });
              }}>
              검색
            </button>
            <button
              className="pa-button white wxl"
              onClick={() => {
                const resetStartDate = new Date(new Date().getFullYear(), new Date().getMonth());
                const resetEndDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1);
                setQuery({
                  startDate: resetStartDate,
                  endDate: resetEndDate,
                  saleType: '',
                  page: 0,
                  size: 10,
                });
                setCheckedSaleType(['class', 'commerce']);
                setSearchPeriod({ startDate: resetStartDate, endDate: resetEndDate });
              }}>
              초기화
            </button>
          </div>
        </section>
      </section>
      <hr className="page-divider" />
      <section className="page-content">
        <section
          className="page-content__btn-container"
          style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ fontFamily: 'Pretendard', fontSize: '16px', fontWeight: 600, color: '#424242' }}>
            목록 <span style={{ color: '#FF3D8F' }}>{pagination?.total || 0}</span>개
          </div>
          <ExcelButton
            prefixTitle="[정산]아이고고"
            label="엑셀다운"
            getExcelFile={() => api.getSellerSettlementExcelFile(query)}
          />
        </section>
        <Table
          style={{ whiteSpace: 'pre-wrap' }}
          bordered
          dataSource={settlementTargetsQuery?.data?.data?.contents}
          columns={TABLE_COLUMNS}
          scroll={{ x: 'max-content' }}
          pagination={pagination}
          onChange={(e) => setQuery((prev) => ({ ...prev, page: (e.current || 2) - 1 }))}
          loading={settlementTargetsQuery.isLoading}
          footer={() => (
            <SettlementTableFooter
              settlementAmount={settlementTargetsQuery.data?.data?.summary?.settlementAmount || 0}
              pendingSettlementAmount={settlementTargetsQuery.data?.data?.summary?.pendingSettlementAmount || 0}
            />
          )}
        />
      </section>
    </section>
  );
};

export default SettlementsPage;
