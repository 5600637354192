import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { getUniqueKeyFromUUID } from '@utils/string';
import { APPLE_CLIENT_ID, APPLE_LOGIN_REDIRECT_URL, KAKAO_APP_KEY, STAGE } from '@variables';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

Bugsnag.start({
  apiKey: 'a411be531107bc10d58c534f50cbf969',
  plugins: [new BugsnagPluginReact()],
  releaseStage: STAGE,
  enabledReleaseStages: ['dev', 'prod'],
  redactedKeys: ['access_token', /^password$/i],
});
BugsnagPerformance.start({ apiKey: 'a411be531107bc10d58c534f50cbf969' });

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

if (Kakao) {
  Kakao.init(KAKAO_APP_KEY);
}

if (window.AppleID) {
  AppleID.auth.init({
    clientId: APPLE_CLIENT_ID,
    scope: 'email',
    redirectURI: APPLE_LOGIN_REDIRECT_URL,
    state: getUniqueKeyFromUUID(),
    usePopup: false,
  });
}

root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <BrowserRouter>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </HelmetProvider>
      </BrowserRouter>
    </React.StrictMode>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
