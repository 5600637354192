// import JoinPage from '@pages/JoinPage';
import RedirectPage from '@pages/RedirectPage';
import SettlementsPage from '@pages/SettlementsPage';
import {
  AccountPage,
  AuthApple,
  AuthKakao,
  AuthNaver,
  ChannelOrdersPage,
  DashboardPage,
  InvoicePage,
  LoginPage,
  MembershipProductsPage,
  MembershipUsersPage,
  NotFoundPage,
  NoticeDetailPage,
  NoticePage,
  ProductsPage,
  ProfileEditPage,
  ReviewsPage,
  SalesHistoryPage,
  UserContentsPage,
} from '@pages/index';

const routes = [
  { path: '/auth/kakao', element: <AuthKakao /> },
  { path: '/auth/naver', element: <AuthNaver /> },
  { path: '/auth/apple', element: <AuthApple /> },
  { path: '/dashboard', element: <DashboardPage /> },
  { path: '/invoices', element: <InvoicePage /> },
  { path: '/login', element: <LoginPage /> },
  // { path: '/join', element: <JoinPage /> },
  { path: '/profile', element: <ProfileEditPage /> },
  { path: '/account', element: <AccountPage /> },
  { path: '/products', element: <ProductsPage /> },
  { path: '/notice', element: <NoticePage /> },
  { path: '/notice/:noticeId', element: <NoticeDetailPage /> },
  { path: '/reviews', element: <ReviewsPage /> },
  { path: '/platform-orders', element: <SalesHistoryPage /> },
  { path: '/channel-orders', element: <ChannelOrdersPage /> },
  { path: '/settlements', element: <SettlementsPage /> },
  { path: '/user-contents', element: <UserContentsPage /> },
  { path: '/membership-users', element: <MembershipUsersPage /> },
  { path: '/membership-products', element: <MembershipProductsPage /> },
  { path: '/', element: <RedirectPage /> },
  { path: '*', element: <NotFoundPage /> },
];

export default routes;
