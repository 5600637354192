import { AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';
import moment from 'moment';
import { useAuthentication } from '@hooks/authentication';

interface ExcelButtonProps {
  prefixTitle: string;
  label: string;
  startDate?: Dayjs | '';
  endDate?: Dayjs | '';
  getExcelFile: () => Promise<AxiosResponse<any, any>>;
}

const ExcelButton: React.FC<ExcelButtonProps> = (props) => {
  const { authStore } = useAuthentication({ loginRequired: true });
  const { prefixTitle, label, startDate, endDate, getExcelFile } = props;

  return (
    <button
      className="excel-btn"
      onClick={async () => {
        if (window.confirm('파일을 다운로드 하시겠습니까?')) {
          const today = new Date();
          const prefix = `${prefixTitle}_${authStore.user?.profile?.name}_`;
          const startDateText = startDate ? `${startDate.format('YYYYMMDD')}` : '';
          const filename = `${prefix}${startDateText}~${endDate ? endDate.format('YYYYMMDD') : moment(today).format('YYYYMMDD')}`;

          const blob = await getExcelFile();

          if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), filename);
          } else {
            const url = window.URL.createObjectURL(blob.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode && link.parentNode.removeChild(link);
          }
        }
      }}>
      {label}
    </button>
  );
};

export default ExcelButton;
