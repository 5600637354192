import React from 'react';
import { ReactComponent as CloseIcon } from '@assets/images/x.svg';
import useCommentModalStore from '@stores/commentModalStore';

export const CommentModal: React.FC = () => {
  const { show, data, close } = useCommentModalStore();
  if (!show) {
    return <></>;
  }

  return (
    <div
      key={`comment-modal-${data?.title}`}
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div
        style={{
          position: 'relative',
          backgroundColor: '#fff',
          width: '100%',
          maxWidth: '440px',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '60px',
            backgroundColor: '#000',
            padding: '0 20px',
          }}>
          <div style={{ fontSize: '18px', fontWeight: 700, color: '#fff' }}>{data?.title}</div>
          <div style={{ cursor: 'pointer' }} onClick={() => close()}>
            <CloseIcon />
          </div>
        </div>
        <div
          style={{
            padding: '24px 20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px',
          }}>
          <div style={{ width: '100%' }}>
            {data?.subTitle && (
              <div
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#424242',
                  marginBottom: '12px',
                }}>
                {data.subTitle}
              </div>
            )}
            {data?.comment && <div className="comment">{data.comment}</div>}
          </div>
          <div
            style={{
              boxSizing: 'border-box',
              width: '100%',
              padding: '10px',
              border: '1px solid #D5D8DD',
              height: '200px',
              whiteSpace: 'pre-wrap',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: 400,
              color: '#424242',
            }}>
            {data?.content}
          </div>
          <button className="pa-button white wl" style={{ marginTop: '20px' }} onClick={() => close()}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
