type ErrorType = "get" | "set" | "delete";

const transErrorMessage = (type: ErrorType) => {
  switch (type) {
    case "get":
      return "로컬 스토리지에 값을 가져오는 도중 오류가 발생했습니다.";
    case "set":
      return "로컬 스토리지에 값을 저장하는 도중 오류가 발생했습니다.";
    case "delete":
      return "로컬 스토리지에 값을 삭제하는 도중 오류가 발생했습니다.";
  }
};

export const getStorage = <T>(key: string, defaulValue?: unknown): T => {
  try {
    const jsonValue = localStorage.getItem(key);

    return jsonValue ? JSON.parse(jsonValue) : defaulValue;
  } catch (error) {
    throw new Error(transErrorMessage("get"));
  }
};

export const setStorage = (key: string, value: unknown) => {
  try {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem(key, jsonValue);
  } catch {
    throw new Error(transErrorMessage("set"));
  }
};

export const deleteStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch {
    throw new Error(transErrorMessage("delete"));
  }
};
