import React, { useEffect, useRef, useState } from 'react';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import './MonthPicker.scss';

dayjs.locale('ko');

export interface MonthPickerProps {
  value?: Date;
  onChange?: (value: Date) => void;
}

export const MonthPicker: React.FC<MonthPickerProps> = (props) => {
  const timeRef = useRef<any>();
  const [dateString, setDateString] = useState<string>();
  const [date, setDate] = useState<Date>();

  useEffect(() => {
    const dateStr = dateString || dayjs(date).format('YYYY-MM-DD');
    const newDate = new Date(Date.parse(`${dateStr}`));
    setDate(newDate);

    if (dateString) {
      props.onChange && props.onChange(newDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateString]);

  useEffect(() => {
    if (props.value) {
      setDateString(dayjs(props.value).format('YYYY-MM-DD'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <div className="month-picker" style={{ display: 'flex', marginTop: '8px', gap: '8px' }}>
      <DatePicker
        locale={{
          ...locale,
          lang: {
            ...locale.lang,
            shortMonths: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
          },
        }}
        picker={'month'}
        allowClear={false}
        style={{ flex: 1, height: '40px' }}
        placeholder={'월 선택'}
        value={dayjs(date)}
        onChange={(e) => {
          setDate(e?.toDate());
          setDateString(e?.format('YYYY-MM-DD'));
          if (timeRef.current) {
            timeRef.current.showPicker();
          }
        }}
      />
    </div>
  );
};

export default MonthPicker;
