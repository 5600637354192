import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import md5 from 'md5';
import * as api from '@apis/auth';
import { User } from '@models/auth';
import { LoginStatus, useAuthStore } from '@stores/authStore';
import { isPartnerRole } from '@utils/common';
import { getAfterLoginRedirectUrl, setAfterLoginRedirectUrl, setLastLoginType } from '@utils/string';
import { APP_BASE_PATH } from '@variables';
import { useAppSocket } from './appSocket';

export const useAuthentication = (options?: {
  seller?: boolean;
  tutor?: boolean;
  loginRequired?: boolean;
  closeAndGoBack?: boolean;
  disableLoginRefresh?: boolean;
}) => {
  const [errorResponse, setErrorResponse] = useState<any>();
  const appSocket = useAppSocket();
  const authStore = useAuthStore();
  const navigate = useNavigate();

  const redirect = () => {
    const redirectUrl = getAfterLoginRedirectUrl();
    navigate(redirectUrl || `/`, { replace: true });
  };

  const setUser = (user?: User) => {
    const savedUser = md5(JSON.stringify(authStore.user || {}));
    const newUser = md5(JSON.stringify(user || {}));

    if (savedUser !== newUser) {
      if (user) {
        appSocket.sendEvent({ type: 'login', data: user });
        setLastLoginType(user.loginType);
      } else {
        appSocket.sendEvent({ type: 'logout', data: authStore.user });
      }
      authStore.setUser(user);
    }
  };

  const showLoginErrorToast = () => {
    setTimeout(() => {
      toast.dark('탈퇴한 사용자이거나 일치하는 사용자 정보가 없습니다.', {
        toastId: 'not-info',
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        bodyStyle: {
          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
        theme: 'dark',
      });
    }, 100);
  };

  const mutationLogin = useMutation({
    mutationFn: api.login,
    onSuccess: async () => {
      try {
        const response = await api.getMe();
        setUser(response.data);
        // eslint-disable-next-line no-empty
      } catch {}
    },
    onError: () => {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh-token');
      showLoginErrorToast();
    },
  });

  const mutationDeleteAcount = useMutation({
    mutationFn: api.deleteMe,
    onSuccess: async () => {
      setTimeout(() => {
        toast.dark('회원탈퇴 되었습니다.', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          bodyStyle: {
            color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
          },
          theme: 'dark',
        });
      }, 100);

      authStore.setUser(undefined);
      // appNavigation.to(`${APP_BASE_PATH}`);
    },
  });

  const mutationJoin = useMutation({
    mutationFn: api.joinPartner,
    onSuccess: async () => {
      try {
        const response = await api.getMe();
        setUser(response.data);
        setErrorResponse(undefined);
        navigate(`${APP_BASE_PATH}`, { replace: true });
        // eslint-disable-next-line no-empty
      } catch {}
    },
    onError: (error) => {
      setErrorResponse(error);
    },
  });

  const mutationLoginWithKakao = useMutation({
    mutationFn: api.loginKakao,
    onSuccess: async (loginResult) => {
      if (loginResult.data?.kakaoUser) {
        navigate(`${APP_BASE_PATH}join`, { state: { kakaoUser: loginResult.data?.kakaoUser }, replace: true });
      } else {
        try {
          const response = await api.getMe();
          setUser(response.data);
          redirect();
          // eslint-disable-next-line no-empty
        } catch {
          redirect();
        }
      }
    },
    onError: async () => {
      try {
        const response = await api.getMe();
        setUser(response.data);
        redirect();
        // eslint-disable-next-line no-empty
      } catch {
        // appNavigation.to(`${APP_BASE_PATH}`);
        showLoginErrorToast();
        redirect();
      }
    },
  });

  const mutationLoginWithNaver = useMutation({
    mutationFn: api.loginNaver,
    onSuccess: async (loginResult) => {
      if (loginResult.data?.naverUser) {
        navigate(`${APP_BASE_PATH}join`, { state: { naverUser: loginResult.data?.naverUser }, replace: true });
      } else {
        try {
          const response = await api.getMe();
          setUser(response.data);
          redirect();
          // eslint-disable-next-line no-empty
        } catch (e) {
          redirect();
        }
      }
    },
    onError: async () => {
      try {
        const response = await api.getMe();
        setUser(response.data);

        redirect();
        // eslint-disable-next-line no-empty
      } catch {
        // appNavigation.to(`${APP_BASE_PATH}`);
        showLoginErrorToast();
        redirect();
      }
    },
  });

  const mutationLoginWithApple = useMutation({
    mutationFn: api.loginApple,
    onSuccess: async (loginResult) => {
      if (loginResult.data?.appleUser) {
        navigate(`${APP_BASE_PATH}join`, { state: { appleUser: loginResult.data?.appleUser }, replace: true });
      } else {
        try {
          const response = await api.getMe();
          setUser(response.data);
          redirect();
          // eslint-disable-next-line no-empty
        } catch {
          redirect();
        }
      }
    },
    onError: () => {
      // appNavigation.to(`${APP_BASE_PATH}`);
      showLoginErrorToast();
      redirect();
    },
  });

  const logoutWithoutBackMutation = useMutation({
    mutationFn: api.logout,
    onSuccess: () => {
      Cookies.remove('Authentication');
      Cookies.remove('AuthRefresh');
      setUser(undefined);
      localStorage.removeItem('token');
      localStorage.removeItem('refresh-token');

      setTimeout(() => {
        toast.dark('로그아웃 되었습니다.', {
          toastId: 'logout',
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          bodyStyle: {
            color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
          },
          theme: 'dark',
        });
      }, 100);
    },
    onError: () => {
      setUser(undefined);
    },
  });

  const mutationLogout = useMutation({
    onMutate: () => {
      setAfterLoginRedirectUrl(window.location.pathname);
    },
    mutationFn: api.logout,
    onSuccess: () => {
      navigate('/login');
      Cookies.remove('Authentication');
      Cookies.remove('AuthRefresh');
      setUser(undefined);
      localStorage.removeItem('token');
      localStorage.removeItem('refresh-token');

      setTimeout(() => {
        toast.dark('로그아웃 되었습니다.', {
          toastId: 'logout',
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          bodyStyle: {
            color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
          },
          theme: 'dark',
        });
      }, 100);
    },
    onError: () => {
      setUser(undefined);
    },
  });

  const checkLogin = async () => {
    let user = undefined;
    try {
      const response = await api.getMe();
      if (response.data.deletedAt) {
        logout();
      }
      user = response.data;
      // eslint-disable-next-line no-empty
    } catch {}

    if (!user) {
      try {
        const response = await api.refresh();
        if (response.status === 200) {
          const meResponse = await api.getMe();
          if (meResponse.data.deletedAt) {
            logout();
          }
          user = meResponse.data;
        }
        // eslint-disable-next-line no-empty
      } catch {}
    }

    if (!isPartnerRole(user)) {
      logout();
      navigate('/login');

      setTimeout(() => {
        toast.dark('판매자 회원만 사용할 수 있습니다', {
          toastId: 'only-seller',
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          bodyStyle: {
            color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
          },
          theme: 'dark',
        });
      }, 100);

      return false;
    }

    setUser(user);

    if (!user && options?.loginRequired) {
      navigate('/login');
    }

    return !!user;
  };

  const refreshLogin = () => {
    if (authStore.status !== LoginStatus.ANONYMOUS || options?.loginRequired) {
      checkLogin();
    }
  };

  useEffect(() => {
    if (!options?.disableLoginRefresh) {
      refreshLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    appSocket.sendEvent({ type: 'logout', data: authStore.user });
    mutationLogout.mutate();
  };

  return {
    authStore,
    login: mutationLogin.mutate,
    loginError: mutationLogin.error,
    logout,
    logoutWithoutBack: logoutWithoutBackMutation.mutate,
    loginKakao: mutationLoginWithKakao.mutate,
    loginNaver: mutationLoginWithNaver.mutate,
    loginApple: mutationLoginWithApple.mutate,
    join: mutationJoin.mutate,
    error: errorResponse,
    setRedirectUrl: setAfterLoginRedirectUrl,
    checkLogin: checkLogin,
    deleteMe: mutationDeleteAcount.mutate,
  };
};
