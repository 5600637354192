import { CDN_BASE_URL } from '@variables';

const DEFAULT_IMAGE_FORMAT = 'image/webp';

export interface ImageResizeOption {
  maxWidth?: number;
  maxHeight?: number;
  format?: string;
}

export interface ImageResizeResult {
  image: Blob;
  type: string;
}

export const resizeImage = async (imageBlob: Blob, options?: ImageResizeOption): Promise<ImageResizeResult> => {
  if (!imageBlob.type.startsWith('image')) {
    return await new Promise<ImageResizeResult>((resolve) => {
      resolve({ image: imageBlob, type: imageBlob.type });
    });
  }

  const { maxWidth, maxHeight, format } = options || {};
  const imageFormat = format || DEFAULT_IMAGE_FORMAT;

  const canvas = document.createElement('canvas');
  const canvasContext = canvas.getContext('2d');
  const URL = window.URL || window.webkitURL;
  const objectUrl = URL.createObjectURL(imageBlob);
  const resultImageBlob = await new Promise<ImageResizeResult>((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      let ratio = 1;
      let targetWidth = image.width;
      let targetHeight = image.height;

      if (maxWidth && image.width > maxWidth) {
        ratio = maxWidth / image.width;
      }

      if (maxHeight && image.height > maxHeight) {
        const heightRatio = maxHeight / image.height;
        ratio = Math.min(ratio, heightRatio);
      }

      targetWidth = Math.floor(((image.width * ratio) / 4) * 4);
      targetHeight = Math.floor(((image.height * ratio) / 4) * 4);

      canvas.width = targetWidth;
      canvas.height = targetHeight;

      if (targetWidth > 16383 || targetHeight > 16383) {
        let data = { property: 'width', originSize: image.width, expectedSize: 16382 / ratio };

        if (targetHeight > 16383) {
          data = { property: 'height', originSize: image.height, expectedSize: Math.floor((16382 / ratio / 4) * 4) };
        }
        reject({ code: 'TOO_LARGE_IMAGE', ...data });
      }

      if (canvasContext) {
        canvasContext.drawImage(image, 0, 0, targetWidth, targetHeight);
        canvas.toBlob((blob) => {
          if (blob) {
            resolve({ image: blob, type: blob.type });
          } else {
            resolve({ image: imageBlob, type: imageBlob.type });
          }
        }, imageFormat);
      } else {
        reject({ code: 'CANNOT_GET_CANVAS_CONTEXT' });
      }
      URL.revokeObjectURL(objectUrl);
    };

    image.onerror = (error) => {
      reject(error);
    };

    image.src = objectUrl;
  });

  return resultImageBlob;
};

const CLOUDINARY_DIRECTORIES = [
  '/mainHeaderImages/',
  '/blog-covers/',
  '/chatting/',
  '/edufessional-programs/',
  '/edufessionals/',
  '/magazineImages/',
  '/notices/',
  '/profileimage/',
  '/program-images/',
  '/report/',
  '/teacher-contract/',
  '/tutorcertificate/',
  '/image/jpeg/',
];

export const cdnImageUrl = (url?: string) => {
  if (!url) {
    return url;
  }

  if (url.startsWith('https://res.cloudinary.com/igo2igo') || url.startsWith('http://res.cloudinary.com/igo2igo')) {
    const found = CLOUDINARY_DIRECTORIES.find((dir) => url.includes(dir));
    if (found) {
      const fileNames = url.split(found);
      return `${CDN_BASE_URL}/cloudinary${found}${fileNames[1]}`;
    }
  }

  if (
    url.startsWith('blob') ||
    url.startsWith('http') ||
    url.startsWith('data:image') ||
    url.startsWith('/static/media')
  ) {
    return url;
  }

  return `${CDN_BASE_URL}${url}`;
};
