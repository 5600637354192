import { lazy } from 'react';
import { getStorage, setStorage } from './localStorage';

export const retryLazy = (componentImport: any) =>
  lazy(async () => {
    const pageAlreadyRefreshed = JSON.parse(getStorage('pageRefreshed') || 'false');
    try {
      const component = await componentImport();
      setStorage('pageRefreshed', 'false');
      return component;
    } catch (error) {
      if (!pageAlreadyRefreshed) {
        setStorage('pageRefreshed', 'true');
        return window.location.reload();
      }
      throw error;
    }
  });
