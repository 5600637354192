import { PagenatedResponse } from '@models/common';
import PaymentStatement, {
  ChangeClassScheduleBody,
  ChangeCommerceOptionBody,
  GetPaymentStatementsQuery,
  GetPurchasesQuery,
  RefundBody,
} from '@models/paymentStatement';
import { ProductOrderViewModel } from '@models/productOrderView';
import { SalesChannelOrder } from '@models/sales-channels';
import { apiClient } from '.';

export interface ChangeScheduleBody {
  paymentStatementId: string;
  body: ChangeClassScheduleBody | ChangeCommerceOptionBody;
}

export const getPaymentStatement = async (query: GetPaymentStatementsQuery) => {
  return apiClient.get<PagenatedResponse<PaymentStatement>>('/partner/v1/payment-statements', { params: query });
};

export const getPaymentStatementByClassInvoiceId = async (classInvoiceId: string) => {
  return apiClient.get<PaymentStatement>(`/partner/v1/payment-statements/product/class-invoice/${classInvoiceId}`);
};

export const getPaymentStatementLastDate = async () => {
  return apiClient.get<{ platform: number; naver: number }>('/partner/v1/payment-statements/last-modified');
};

export const getSellerPurchasesExcelFile = async (query: GetPaymentStatementsQuery) => {
  return apiClient.get<any>('/partner/v1/payment-statements/export', {
    params: query,
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const getSellerSettlementExcelFile = async (query: GetPaymentStatementsQuery) => {
  return apiClient.get<any>('/partner/v1/payment-statements/export/settlement', {
    params: query,
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const submitRefundDecision = async (data: { paymentStatementId: string; body: RefundBody }) => {
  return apiClient.patch<PaymentStatement>(
    `/partner/v1/payment-statements/${data.paymentStatementId}/refund`,
    data.body,
  );
};

export const getChannelOrders = async (query: GetPurchasesQuery) => {
  return apiClient.get<PagenatedResponse<SalesChannelOrder>>('/partner/v1/sales-channels', {
    params: query,
  });
};

export const getChannelOrdersExcelFile = async (query: GetPurchasesQuery) => {
  return apiClient.get<any>('/partner/v1/sales-channels/export', {
    params: query,
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const changeSchedule = async (data: ChangeScheduleBody) => {
  return apiClient.patch(`/partner/v1/payment-statements/${data.paymentStatementId}/planned-date`, data.body);
};

export const getSettlements = async (query: GetPaymentStatementsQuery) => {
  return apiClient.get<PagenatedResponse<ProductOrderViewModel>>('/partner/v1/payment-statements/settlements', {
    params: query,
  });
};

export const getCurrentMonthSettlementSummary = async () => {
  return apiClient.get<{ settlementAmount: number; pendingSettlementAmount: number }>(
    '/partner/v1/payment-statements/settlement-summary',
  );
};
