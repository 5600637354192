import { Roles } from '@consts/role';
import { User } from '@models/auth';
import { Membership } from '@models/membership';
import { Product } from '@models/product';

export enum MessageActions {
  UPDATE_TOKEN = 'update_token',
  // MOVE_FROM_NOTIFICATION = 'move_from_notification',
  MOVE_FROM_NOTIFICATION = 'notification',
  BACK_TO_PREVIOUS = 'back_to_previous',
  SHARE_URL = 'share_url',
  GET_DEVICEID = 'get_deviceId',
  CLOSE_MODAL = 'close_modal',
  GET_ASYNC_STORAGE_LOGIN_USERINFO = 'get_async_storage_login_userinfo',
  DONE_LEGACY_LOGIN = 'done_legacy_login',
  LOGOUT = 'logout',
  LOGIN = 'login',
  DENY_PARENT_ETC_PUSH = 'deny_parent_etc_push',
  ACCPET_PARENT_ETC_PUSH = 'accept_parent_etc_push',
  DENY_TUTOR_ETC_PUSH = 'deny_tutor_etc_push',
  ACCPET_TUTOR_ETC_PUSH = 'accept_tutor_etc_push',
  DENY_ETC_PUSH = 'deny_etc_push',
  POP_STACK = 'pop_stack',
  POP_STACK_SWIPE_GESTURE = 'pop_stack_swipe_gesture',
  PUSH_STACK = 'push_stack',
  GET_USER_INFO = 'get_user_info',
  MOVE_FROM_DEEPLINK = 'move_from_deeplink',
  DOWNLOAD_IMAGES = 'download_images',
  SEND_INAPP_BROWSER = 'send_inapp_browser',
}
export interface NativeMessage {
  type: MessageActions;
  data: object;
}

export const scrollToTop = () => {
  document.body.scrollTo(0, 0);
};

export const isPartnerRole = (user?: User | null) => {
  if (
    user?.roles?.includes(Roles.SELLER) ||
    user?.roles?.includes(Roles.TUTOR) ||
    user?.roles?.includes(Roles.EDITOR) ||
    user?.roles?.includes(Roles.PARTNER) ||
    user?.roles?.includes(Roles.MEMBERSHIP_PARTNER)
  ) {
    return true;
  }

  if (user?.role === Roles.SELLER) {
    return true;
  }

  return false;
};

export const getLocaleText = (location: { state: string; city: string }) => {
  const stateText = location.state.replace('특별시', '').replace('광역시', '').replace(/도$/, '');
  const cityText =
    location.city.length > 2 ? location.city.replace(/시$/, '').replace(/군$/, '').replace(/구$/, '') : location.city;
  return `${stateText} ${cityText}`;
};

export const getNativeAppInfoFromUserAgent = (): {
  isNativeApp: boolean;
  appVersion: string | undefined;
  appPlatform: 'ios' | 'android' | undefined;
} => {
  const isNativeApp = /igogo-react-native-app/i.test(navigator.userAgent);
  let appPlatform: 'ios' | 'android' | undefined = undefined;
  let appVersion: string | undefined = undefined;
  if (isNativeApp) {
    if (navigator.userAgent.endsWith('ios')) {
      appPlatform = 'ios';
    } else if (navigator.userAgent.endsWith('android')) {
      appPlatform = 'android';
    }
    const regex = /igogo-react-native-app\/(\S+)/;
    const match = navigator.userAgent.match(regex);
    if (match) {
      appVersion = match[1];
    }
  }
  return {
    isNativeApp,
    appVersion,
    appPlatform,
  };
};

export const compareVersion = (currentVersion: string | undefined, targetVersion: string): boolean => {
  if (!currentVersion) {
    return true;
  }
  const parseVersion = (version: string) => {
    const [major = 0, minor = 0, patch = 0] = version.split('.').map((item) => Number(item));
    return { major, minor, patch };
  };
  const current = parseVersion(currentVersion);
  const target = parseVersion(targetVersion);
  if (target.major !== current.major) {
    return target.major - current.major > 0;
  }
  if (target.minor !== current.minor) {
    return target.minor - current.minor > 0;
  }
  return target.patch - current.patch > 0;
};

export const sendMessageToNative = (message: NativeMessage) => {
  return (window as any).ReactNativeWebView?.postMessage(JSON.stringify(message));
};

export const openLink = (url: string) => {
  const { isNativeApp, appVersion } = getNativeAppInfoFromUserAgent();
  const hasParameter = !!url?.split('?')?.[1];
  let canOpenInAppBrowser = isNativeApp && appVersion && !url?.startsWith('tel');
  if (hasParameter) {
    const isUpdateRequired = compareVersion(appVersion, '2.2.9');
    canOpenInAppBrowser = isNativeApp && appVersion && !url?.startsWith('tel') && !isUpdateRequired;
  }

  if (canOpenInAppBrowser) {
    const postMessage = {
      type: MessageActions.SEND_INAPP_BROWSER,
      data: {
        url: url,
      },
    };
    sendMessageToNative(postMessage);
  } else {
    window.open(url, '_blank');
  }
};

export const discountedAmountBy = (amount: number, discountPercent: number) => {
  const result = ((amount || 0) * (100 - (discountPercent || 0))) / 100;
  return Math.floor(result / 10) * 10;
};

export const getAppliedMembership = (product?: Product, userMembership?: Partial<Membership>) => {
  const productMemberships: Partial<Membership>[] = product?.memberships || [];
  const productId = product?._id;
  const appliedMembership = productMemberships.find((membership) => {
    return membership.alias === userMembership?.alias && userMembership?.qualified;
  });

  if (appliedMembership && productId) {
    const specialOfferProducts = appliedMembership.benefits?.specialOfferProducts || {};
    appliedMembership['discountPercent'] =
      specialOfferProducts[productId]?.discountPercent || appliedMembership.discountPercent;
  }

  return appliedMembership;
};
